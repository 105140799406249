.fw400 {
  font-family: mr-eaves-modern, sans-serif;

  font-weight: 400;

  font-style: normal;
}

.fw200 {
  font-family: mr-eaves-modern, sans-serif;

  font-weight: 200;

  font-style: normal;
}

.fw800 {
  font-family: mr-eaves-modern, sans-serif;

  font-weight: 800;

  font-style: normal;
}

.nav-link {
  font-family: mr-eaves-modern, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  font-style: normal;
}

.nav-link-small {
  font-family: mr-eaves-modern, sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  font-style: normal;
}
