.card {
  position: relative;
  height: 20rem;
}

.pos_rel {
  position: relative;
}

.card_name {
  position: absolute;
  bottom: 40px;
  left: 32px;
}

.card_address {
  position: absolute;
  bottom: 16px;
  left: 32px;
}

.product_card {
  position: relative;
}

.product_type {
  position: absolute;
  top: 8px;
  right: 16px;
}

.product_sale {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
}

.product_flag {
  position: absolute;
  bottom: 16px;
  left: 0px;
  width: 80px;
  height: 24px;
}

.cart_remove {
  position: absolute;
  top: 16px;
  right: 16px;
}

.product-image {
  width: 150px;
  height: 100%;
}