/*

    VARIABLES

*/
/*

  DEBUG CHILDREN
  Docs: http://tachyons.io/docs/debug/

  Just add the debug class to any element to see outlines on its
  children.

*/
.debug * {
  outline: 1px solid gold; }

.debug-white * {
  outline: 1px solid white; }

.debug-black * {
  outline: 1px solid black; }

/*

   DEBUG GRID
   http://tachyons.io/docs/debug-grid/

   Can be useful for debugging layout issues
   or helping to make sure things line up perfectly.
   Just tack one of these classes onto a parent element.

*/
.debug-grid {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTRDOTY4N0U2N0VFMTFFNjg2MzZDQjkwNkQ4MjgwMEIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTRDOTY4N0Q2N0VFMTFFNjg2MzZDQjkwNkQ4MjgwMEIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3NjcyQkQ3NjY3QzUxMUU2QjJCQ0UyNDA4MTAwMjE3MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3NjcyQkQ3NzY3QzUxMUU2QjJCQ0UyNDA4MTAwMjE3MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsBS+GMAAAAjSURBVHjaYvz//z8DLsD4gcGXiYEAGBIKGBne//fFpwAgwAB98AaF2pjlUQAAAABJRU5ErkJggg==) repeat top left; }

.debug-grid-16 {
  background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODYyRjhERDU2N0YyMTFFNjg2MzZDQjkwNkQ4MjgwMEIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODYyRjhERDQ2N0YyMTFFNjg2MzZDQjkwNkQ4MjgwMEIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3NjcyQkQ3QTY3QzUxMUU2QjJCQ0UyNDA4MTAwMjE3MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3NjcyQkQ3QjY3QzUxMUU2QjJCQ0UyNDA4MTAwMjE3MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvCS01IAAABMSURBVHjaYmR4/5+BFPBfAMFm/MBgx8RAGWCn1AAmSg34Q6kBDKMGMDCwICeMIemF/5QawEipAWwUhwEjMDvbAWlWkvVBwu8vQIABAEwBCph8U6c0AAAAAElFTkSuQmCC) repeat top left; }

.debug-grid-8-solid {
  background: white url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAAAAAAD/4QMxaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjYtYzExMSA3OS4xNTgzMjUsIDIwMTUvMDkvMTAtMDE6MTA6MjAgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDQyAyMDE1IChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkIxMjI0OTczNjdCMzExRTZCMkJDRTI0MDgxMDAyMTcxIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkIxMjI0OTc0NjdCMzExRTZCMkJDRTI0MDgxMDAyMTcxIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QjEyMjQ5NzE2N0IzMTFFNkIyQkNFMjQwODEwMDIxNzEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QjEyMjQ5NzI2N0IzMTFFNkIyQkNFMjQwODEwMDIxNzEiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7/7gAOQWRvYmUAZMAAAAAB/9sAhAAbGhopHSlBJiZBQi8vL0JHPz4+P0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHAR0pKTQmND8oKD9HPzU/R0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0f/wAARCAAIAAgDASIAAhEBAxEB/8QAWQABAQAAAAAAAAAAAAAAAAAAAAYBAQEAAAAAAAAAAAAAAAAAAAIEEAEBAAMBAAAAAAAAAAAAAAABADECA0ERAAEDBQAAAAAAAAAAAAAAAAARITFBUWESIv/aAAwDAQACEQMRAD8AoOnTV1QTD7JJshP3vSM3P//Z) repeat top left; }

.debug-grid-16-solid {
  background: white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTExIDc5LjE1ODMyNSwgMjAxNS8wOS8xMC0wMToxMDoyMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NzY3MkJEN0U2N0M1MTFFNkIyQkNFMjQwODEwMDIxNzEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NzY3MkJEN0Y2N0M1MTFFNkIyQkNFMjQwODEwMDIxNzEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo3NjcyQkQ3QzY3QzUxMUU2QjJCQ0UyNDA4MTAwMjE3MSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo3NjcyQkQ3RDY3QzUxMUU2QjJCQ0UyNDA4MTAwMjE3MSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pve6J3kAAAAzSURBVHjaYvz//z8D0UDsMwMjSRoYP5Gq4SPNbRjVMEQ1fCRDg+in/6+J1AJUxsgAEGAA31BAJMS0GYEAAAAASUVORK5CYII=) repeat top left; }

/*

  BOX SIZING

*/
html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
a,
h1, h2, h3, h4, h5, h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
table,
td,
th,
tr,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"],
input[type="url"],
.border-box {
  box-sizing: border-box; }

/*

   ASPECT RATIOS

*/
/* This is for fluid media that is embedded from third party sites like youtube, vimeo etc.
 * Wrap the outer element in aspect-ratio and then extend it with the desired ratio i.e
 * Make sure there are no height and width attributes on the embedded media.
 * Adapted from: https://github.com/suitcss/components-flex-embed
 *
 * Example:
 *
 * <div class="aspect-ratio aspect-ratio--16x9">
 *  <iframe class="aspect-ratio--object"></iframe>
 * </div>
 *
 * */
.aspect-ratio {
  height: 0;
  position: relative; }

.aspect-ratio--16x9 {
  padding-bottom: 56.25%; }

.aspect-ratio--9x16 {
  padding-bottom: 177.77%; }

.aspect-ratio--4x3 {
  padding-bottom: 75%; }

.aspect-ratio--3x4 {
  padding-bottom: 133.33%; }

.aspect-ratio--6x4 {
  padding-bottom: 66.6%; }

.aspect-ratio--4x6 {
  padding-bottom: 150%; }

.aspect-ratio--8x5 {
  padding-bottom: 62.5%; }

.aspect-ratio--5x8 {
  padding-bottom: 160%; }

.aspect-ratio--7x5 {
  padding-bottom: 71.42%; }

.aspect-ratio--5x7 {
  padding-bottom: 140%; }

.aspect-ratio--1x1 {
  padding-bottom: 100%; }

.aspect-ratio--object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100; }

@media screen and (min-width: 30em) {
  .aspect-ratio-ns {
    height: 0;
    position: relative; }
  .aspect-ratio--16x9-ns {
    padding-bottom: 56.25%; }
  .aspect-ratio--9x16-ns {
    padding-bottom: 177.77%; }
  .aspect-ratio--4x3-ns {
    padding-bottom: 75%; }
  .aspect-ratio--3x4-ns {
    padding-bottom: 133.33%; }
  .aspect-ratio--6x4-ns {
    padding-bottom: 66.6%; }
  .aspect-ratio--4x6-ns {
    padding-bottom: 150%; }
  .aspect-ratio--8x5-ns {
    padding-bottom: 62.5%; }
  .aspect-ratio--5x8-ns {
    padding-bottom: 160%; }
  .aspect-ratio--7x5-ns {
    padding-bottom: 71.42%; }
  .aspect-ratio--5x7-ns {
    padding-bottom: 140%; }
  .aspect-ratio--1x1-ns {
    padding-bottom: 100%; }
  .aspect-ratio--object-ns {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100; } }

@media (min-width: 48em) {
  .aspect-ratio-m {
    height: 0;
    position: relative; }
  .aspect-ratio--16x9-m {
    padding-bottom: 56.25%; }
  .aspect-ratio--9x16-m {
    padding-bottom: 177.77%; }
  .aspect-ratio--4x3-m {
    padding-bottom: 75%; }
  .aspect-ratio--3x4-m {
    padding-bottom: 133.33%; }
  .aspect-ratio--6x4-m {
    padding-bottom: 66.6%; }
  .aspect-ratio--4x6-m {
    padding-bottom: 150%; }
  .aspect-ratio--8x5-m {
    padding-bottom: 62.5%; }
  .aspect-ratio--5x8-m {
    padding-bottom: 160%; }
  .aspect-ratio--7x5-m {
    padding-bottom: 71.42%; }
  .aspect-ratio--5x7-m {
    padding-bottom: 140%; }
  .aspect-ratio--1x1-m {
    padding-bottom: 100%; }
  .aspect-ratio--object-m {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100; } }

@media (min-width: 70em) {
  .aspect-ratio-l {
    height: 0;
    position: relative; }
  .aspect-ratio--16x9-l {
    padding-bottom: 56.25%; }
  .aspect-ratio--9x16-l {
    padding-bottom: 177.77%; }
  .aspect-ratio--4x3-l {
    padding-bottom: 75%; }
  .aspect-ratio--3x4-l {
    padding-bottom: 133.33%; }
  .aspect-ratio--6x4-l {
    padding-bottom: 66.6%; }
  .aspect-ratio--4x6-l {
    padding-bottom: 150%; }
  .aspect-ratio--8x5-l {
    padding-bottom: 62.5%; }
  .aspect-ratio--5x8-l {
    padding-bottom: 160%; }
  .aspect-ratio--7x5-l {
    padding-bottom: 71.42%; }
  .aspect-ratio--5x7-l {
    padding-bottom: 140%; }
  .aspect-ratio--1x1-l {
    padding-bottom: 100%; }
  .aspect-ratio--object-l {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100; } }

/*

   IMAGES
   Docs: http://tachyons.io/docs/elements/images/

*/
/* Responsive images! */
img {
  max-width: 100%; }

/*

   BACKGROUND SIZE
   Docs: http://tachyons.io/docs/themes/background-size/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
/*
  Often used in combination with background image set as an inline style
  on an html element.
*/
.cover {
  background-size: cover !important; }

.contain {
  background-size: contain !important; }

@media screen and (min-width: 30em) {
  .cover-ns {
    background-size: cover !important; }
  .contain-ns {
    background-size: contain !important; } }

@media (min-width: 48em) {
  .cover-m {
    background-size: cover !important; }
  .contain-m {
    background-size: contain !important; } }

@media (min-width: 70em) {
  .cover-l {
    background-size: cover !important; }
  .contain-l {
    background-size: contain !important; } }

/*

    BACKGROUND POSITION

    Base:
    bg = background

    Modifiers:
    -center = center center
    -top = top center
    -right = center right
    -bottom = bottom center
    -left = center left

    Media Query Extensions:
      -ns = not-small
      -m  = medium
      -l  = large

 */
.bg-center {
  background-repeat: no-repeat;
  background-position: center center; }

.bg-top {
  background-repeat: no-repeat;
  background-position: top center; }

.bg-right {
  background-repeat: no-repeat;
  background-position: center right; }

.bg-bottom {
  background-repeat: no-repeat;
  background-position: bottom center; }

.bg-left {
  background-repeat: no-repeat;
  background-position: center left; }

@media screen and (min-width: 30em) {
  .bg-center-ns {
    background-repeat: no-repeat;
    background-position: center center; }
  .bg-top-ns {
    background-repeat: no-repeat;
    background-position: top center; }
  .bg-right-ns {
    background-repeat: no-repeat;
    background-position: center right; }
  .bg-bottom-ns {
    background-repeat: no-repeat;
    background-position: bottom center; }
  .bg-left-ns {
    background-repeat: no-repeat;
    background-position: center left; } }

@media (min-width: 48em) {
  .bg-center-m {
    background-repeat: no-repeat;
    background-position: center center; }
  .bg-top-m {
    background-repeat: no-repeat;
    background-position: top center; }
  .bg-right-m {
    background-repeat: no-repeat;
    background-position: center right; }
  .bg-bottom-m {
    background-repeat: no-repeat;
    background-position: bottom center; }
  .bg-left-m {
    background-repeat: no-repeat;
    background-position: center left; } }

@media (min-width: 70em) {
  .bg-center-l {
    background-repeat: no-repeat;
    background-position: center center; }
  .bg-top-l {
    background-repeat: no-repeat;
    background-position: top center; }
  .bg-right-l {
    background-repeat: no-repeat;
    background-position: center right; }
  .bg-bottom-l {
    background-repeat: no-repeat;
    background-position: bottom center; }
  .bg-left-l {
    background-repeat: no-repeat;
    background-position: center left; } }

/*

   OUTLINES

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.outline {
  outline: 1px solid; }

.outline-transparent {
  outline: 1px solid transparent; }

.outline-0 {
  outline: 0; }

@media screen and (min-width: 30em) {
  .outline-ns {
    outline: 1px solid; }
  .outline-transparent-ns {
    outline: 1px solid transparent; }
  .outline-0-ns {
    outline: 0; } }

@media (min-width: 48em) {
  .outline-m {
    outline: 1px solid; }
  .outline-transparent-m {
    outline: 1px solid transparent; }
  .outline-0-m {
    outline: 0; } }

@media (min-width: 70em) {
  .outline-l {
    outline: 1px solid; }
  .outline-transparent-l {
    outline: 1px solid transparent; }
  .outline-0-l {
    outline: 0; } }

/*

    BORDERS
    Docs: http://tachyons.io/docs/themes/borders/

    Base:
      b = border

    Modifiers:
      a = all
      t = top
      r = right
      b = bottom
      l = left
      n = none

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.ba {
  border-style: solid;
  border-width: 1px; }

.bt {
  border-top-style: solid;
  border-top-width: 1px; }

.br {
  border-right-style: solid;
  border-right-width: 1px; }

.bb {
  border-bottom-style: solid;
  border-bottom-width: 1px; }

.bl {
  border-left-style: solid;
  border-left-width: 1px; }

.bn {
  border-style: none;
  border-width: 0; }

@media screen and (min-width: 30em) {
  .ba-ns {
    border-style: solid;
    border-width: 1px; }
  .bt-ns {
    border-top-style: solid;
    border-top-width: 1px; }
  .br-ns {
    border-right-style: solid;
    border-right-width: 1px; }
  .bb-ns {
    border-bottom-style: solid;
    border-bottom-width: 1px; }
  .bl-ns {
    border-left-style: solid;
    border-left-width: 1px; }
  .bn-ns {
    border-style: none;
    border-width: 0; } }

@media (min-width: 48em) {
  .ba-m {
    border-style: solid;
    border-width: 1px; }
  .bt-m {
    border-top-style: solid;
    border-top-width: 1px; }
  .br-m {
    border-right-style: solid;
    border-right-width: 1px; }
  .bb-m {
    border-bottom-style: solid;
    border-bottom-width: 1px; }
  .bl-m {
    border-left-style: solid;
    border-left-width: 1px; }
  .bn-m {
    border-style: none;
    border-width: 0; } }

@media (min-width: 70em) {
  .ba-l {
    border-style: solid;
    border-width: 1px; }
  .bt-l {
    border-top-style: solid;
    border-top-width: 1px; }
  .br-l {
    border-right-style: solid;
    border-right-width: 1px; }
  .bb-l {
    border-bottom-style: solid;
    border-bottom-width: 1px; }
  .bl-l {
    border-left-style: solid;
    border-left-width: 1px; }
  .bn-l {
    border-style: none;
    border-width: 0; } }

/*

   BORDER COLORS
   Docs: http://tachyons.io/docs/themes/borders/

   Border colors can be used to extend the base
   border classes ba,bt,bb,br,bl found in the _borders.css file.

   The base border class by default will set the color of the border
   to that of the current text color. These classes are for the cases
   where you desire for the text and border colors to be different.

   Base:
     b = border

   Modifiers:
   --color-name = each color variable name is also a border color name

*/
.b--black {
  border-color: #000; }

.b--near-black {
  border-color: #111; }

.b--dark-gray {
  border-color: #333; }

.b--mid-gray {
  border-color: #555; }

.b--gray {
  border-color: #777; }

.b--silver {
  border-color: #999; }

.b--light-silver {
  border-color: #aaa; }

.b--moon-gray {
  border-color: #ccc; }

.b--light-gray {
  border-color: #eee; }

.b--near-white {
  border-color: #f4f4f4; }

.b--white {
  border-color: #fff; }

.b--white-90 {
  border-color: rgba(255, 255, 255, 0.9); }

.b--white-80 {
  border-color: rgba(255, 255, 255, 0.8); }

.b--white-70 {
  border-color: rgba(255, 255, 255, 0.7); }

.b--white-60 {
  border-color: rgba(255, 255, 255, 0.6); }

.b--white-50 {
  border-color: rgba(255, 255, 255, 0.5); }

.b--white-40 {
  border-color: rgba(255, 255, 255, 0.4); }

.b--white-30 {
  border-color: rgba(255, 255, 255, 0.3); }

.b--white-20 {
  border-color: rgba(255, 255, 255, 0.2); }

.b--white-10 {
  border-color: rgba(255, 255, 255, 0.1); }

.b--white-05 {
  border-color: rgba(255, 255, 255, 0.05); }

.b--white-025 {
  border-color: rgba(255, 255, 255, 0.025); }

.b--white-0125 {
  border-color: rgba(255, 255, 255, 0.0125); }

.b--black-90 {
  border-color: rgba(0, 0, 0, 0.9); }

.b--black-80 {
  border-color: rgba(0, 0, 0, 0.8); }

.b--black-70 {
  border-color: rgba(0, 0, 0, 0.7); }

.b--black-60 {
  border-color: rgba(0, 0, 0, 0.6); }

.b--black-50 {
  border-color: rgba(0, 0, 0, 0.5); }

.b--black-40 {
  border-color: rgba(0, 0, 0, 0.4); }

.b--black-30 {
  border-color: rgba(0, 0, 0, 0.3); }

.b--black-20 {
  border-color: rgba(0, 0, 0, 0.2); }

.b--black-10 {
  border-color: rgba(0, 0, 0, 0.1); }

.b--black-05 {
  border-color: rgba(0, 0, 0, 0.05); }

.b--black-025 {
  border-color: rgba(0, 0, 0, 0.025); }

.b--black-0125 {
  border-color: rgba(0, 0, 0, 0.0125); }

.b--dark-red {
  border-color: #e7040f; }

.b--red {
  border-color: #ff4136; }

.b--light-red {
  border-color: #ff725c; }

.b--orange {
  border-color: #ff6300; }

.b--gold {
  border-color: #ffb700; }

.b--yellow {
  border-color: #ffd700; }

.b--light-yellow {
  border-color: #fbf1a9; }

.b--purple {
  border-color: #5e2ca5; }

.b--light-purple {
  border-color: #a463f2; }

.b--dark-pink {
  border-color: #d5008f; }

.b--hot-pink {
  border-color: #ff41b4; }

.b--pink {
  border-color: #ff80cc; }

.b--light-pink {
  border-color: #ffa3d7; }

.b--dark-green {
  border-color: #137752; }

.b--green {
  border-color: #19a974; }

.b--light-green {
  border-color: #9eebcf; }

.b--navy {
  border-color: #001b44; }

.b--dark-blue {
  border-color: #00449e; }

.b--blue {
  border-color: #357edd; }

.b--light-blue {
  border-color: #96ccff; }

.b--lightest-blue {
  border-color: #cdecff; }

.b--washed-blue {
  border-color: #f6fffe; }

.b--washed-green {
  border-color: #e8fdf5; }

.b--washed-yellow {
  border-color: #fffceb; }

.b--washed-red {
  border-color: #ffdfdf; }

.b--transparent {
  border-color: transparent; }

.b--inherit {
  border-color: inherit; }

/*

   BORDER RADIUS
   Docs: http://tachyons.io/docs/themes/border-radius/

   Base:
     br   = border-radius

   Modifiers:
     0    = 0/none
     1    = 1st step in scale
     2    = 2nd step in scale
     3    = 3rd step in scale
     4    = 4th step in scale

   Literal values:
     -100 = 100%
     -pill = 9999px

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.br0 {
  border-radius: 0; }

.br1 {
  border-radius: 0.125rem; }

.br2 {
  border-radius: 0.25rem; }

.br3 {
  border-radius: 0.5rem; }

.br4 {
  border-radius: 1rem; }

.br-100 {
  border-radius: 100%; }

.br-pill {
  border-radius: 9999px; }

.br--bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.br--top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.br--right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.br--left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

@media screen and (min-width: 30em) {
  .br0-ns {
    border-radius: 0; }
  .br1-ns {
    border-radius: 0.125rem; }
  .br2-ns {
    border-radius: 0.25rem; }
  .br3-ns {
    border-radius: 0.5rem; }
  .br4-ns {
    border-radius: 1rem; }
  .br-100-ns {
    border-radius: 100%; }
  .br-pill-ns {
    border-radius: 9999px; }
  .br--bottom-ns {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .br--top-ns {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .br--right-ns {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .br--left-ns {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; } }

@media (min-width: 48em) {
  .br0-m {
    border-radius: 0; }
  .br1-m {
    border-radius: 0.125rem; }
  .br2-m {
    border-radius: 0.25rem; }
  .br3-m {
    border-radius: 0.5rem; }
  .br4-m {
    border-radius: 1rem; }
  .br-100-m {
    border-radius: 100%; }
  .br-pill-m {
    border-radius: 9999px; }
  .br--bottom-m {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .br--top-m {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .br--right-m {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .br--left-m {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; } }

@media (min-width: 70em) {
  .br0-l {
    border-radius: 0; }
  .br1-l {
    border-radius: 0.125rem; }
  .br2-l {
    border-radius: 0.25rem; }
  .br3-l {
    border-radius: 0.5rem; }
  .br4-l {
    border-radius: 1rem; }
  .br-100-l {
    border-radius: 100%; }
  .br-pill-l {
    border-radius: 9999px; }
  .br--bottom-l {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .br--top-l {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .br--right-l {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .br--left-l {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; } }

/*

   BORDER STYLES
   Docs: http://tachyons.io/docs/themes/borders/

   Depends on base border module in _borders.css

   Base:
     b = border-style

   Modifiers:
     --none   = none
     --dotted = dotted
     --dashed = dashed
     --solid  = solid

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

 */
.b--dotted {
  border-style: dotted; }

.b--dashed {
  border-style: dashed; }

.b--solid {
  border-style: solid; }

.b--none {
  border-style: none; }

@media screen and (min-width: 30em) {
  .b--dotted-ns {
    border-style: dotted; }
  .b--dashed-ns {
    border-style: dashed; }
  .b--solid-ns {
    border-style: solid; }
  .b--none-ns {
    border-style: none; } }

@media (min-width: 48em) {
  .b--dotted-m {
    border-style: dotted; }
  .b--dashed-m {
    border-style: dashed; }
  .b--solid-m {
    border-style: solid; }
  .b--none-m {
    border-style: none; } }

@media (min-width: 70em) {
  .b--dotted-l {
    border-style: dotted; }
  .b--dashed-l {
    border-style: dashed; }
  .b--solid-l {
    border-style: solid; }
  .b--none-l {
    border-style: none; } }

/*

   BORDER WIDTHS
   Docs: http://tachyons.io/docs/themes/borders/

   Base:
     bw = border-width

   Modifiers:
     0 = 0 width border
     1 = 1st step in border-width scale
     2 = 2nd step in border-width scale
     3 = 3rd step in border-width scale
     4 = 4th step in border-width scale
     5 = 5th step in border-width scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.bw0 {
  border-width: 0; }

.bw1 {
  border-width: 0.125rem; }

.bw2 {
  border-width: 0.25rem; }

.bw3 {
  border-width: 0.5rem; }

.bw4 {
  border-width: 1rem; }

.bw5 {
  border-width: 2rem; }

/* Resets */
.bt-0 {
  border-top-width: 0; }

.br-0 {
  border-right-width: 0; }

.bb-0 {
  border-bottom-width: 0; }

.bl-0 {
  border-left-width: 0; }

@media screen and (min-width: 30em) {
  .bw0-ns {
    border-width: 0; }
  .bw1-ns {
    border-width: 0.125rem; }
  .bw2-ns {
    border-width: 0.25rem; }
  .bw3-ns {
    border-width: 0.5rem; }
  .bw4-ns {
    border-width: 1rem; }
  .bw5-ns {
    border-width: 2rem; }
  .bt-0-ns {
    border-top-width: 0; }
  .br-0-ns {
    border-right-width: 0; }
  .bb-0-ns {
    border-bottom-width: 0; }
  .bl-0-ns {
    border-left-width: 0; } }

@media (min-width: 48em) {
  .bw0-m {
    border-width: 0; }
  .bw1-m {
    border-width: 0.125rem; }
  .bw2-m {
    border-width: 0.25rem; }
  .bw3-m {
    border-width: 0.5rem; }
  .bw4-m {
    border-width: 1rem; }
  .bw5-m {
    border-width: 2rem; }
  .bt-0-m {
    border-top-width: 0; }
  .br-0-m {
    border-right-width: 0; }
  .bb-0-m {
    border-bottom-width: 0; }
  .bl-0-m {
    border-left-width: 0; } }

@media (min-width: 70em) {
  .bw0-l {
    border-width: 0; }
  .bw1-l {
    border-width: 0.125rem; }
  .bw2-l {
    border-width: 0.25rem; }
  .bw3-l {
    border-width: 0.5rem; }
  .bw4-l {
    border-width: 1rem; }
  .bw5-l {
    border-width: 2rem; }
  .bt-0-l {
    border-top-width: 0; }
  .br-0-l {
    border-right-width: 0; }
  .bb-0-l {
    border-bottom-width: 0; }
  .bl-0-l {
    border-left-width: 0; } }

/*

  BOX-SHADOW
  Docs: http://tachyons.io/docs/themes/box-shadow/

  Media Query Extensions:
   -ns = not-small
   -m  = medium
   -l  = large

 */
.shadow-1 {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2); }

.shadow-2 {
  box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2); }

.shadow-3 {
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2); }

.shadow-4 {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2); }

.shadow-5 {
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2); }

@media screen and (min-width: 30em) {
  .shadow-1-ns {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2); }
  .shadow-2-ns {
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2); }
  .shadow-3-ns {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2); }
  .shadow-4-ns {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2); }
  .shadow-5-ns {
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2); } }

@media (min-width: 48em) {
  .shadow-1-m {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2); }
  .shadow-2-m {
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2); }
  .shadow-3-m {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2); }
  .shadow-4-m {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2); }
  .shadow-5-m {
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2); } }

@media (min-width: 70em) {
  .shadow-1-l {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2); }
  .shadow-2-l {
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.2); }
  .shadow-3-l {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2); }
  .shadow-4-l {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2); }
  .shadow-5-l {
    box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.2); } }

/*

   CODE

*/
.pre {
  overflow-x: auto;
  overflow-y: hidden;
  overflow: scroll; }

/*

   COORDINATES
   Docs: http://tachyons.io/docs/layout/position/

   Use in combination with the position module.

   Base:
     top
     bottom
     right
     left

   Modifiers:
     -0  = literal value 0
     -1  = literal value 1
     -2  = literal value 2
     --1 = literal value -1
     --2 = literal value -2

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.top-0 {
  top: 0; }

.right-0 {
  right: 0; }

.bottom-0 {
  bottom: 0; }

.left-0 {
  left: 0; }

.top-1 {
  top: 1rem; }

.right-1 {
  right: 1rem; }

.bottom-1 {
  bottom: 1rem; }

.left-1 {
  left: 1rem; }

.top-2 {
  top: 2rem; }

.right-2 {
  right: 2rem; }

.bottom-2 {
  bottom: 2rem; }

.left-2 {
  left: 2rem; }

.top--1 {
  top: -1rem; }

.right--1 {
  right: -1rem; }

.bottom--1 {
  bottom: -1rem; }

.left--1 {
  left: -1rem; }

.top--2 {
  top: -2rem; }

.right--2 {
  right: -2rem; }

.bottom--2 {
  bottom: -2rem; }

.left--2 {
  left: -2rem; }

.absolute--fill {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

@media screen and (min-width: 30em) {
  .top-0-ns {
    top: 0; }
  .left-0-ns {
    left: 0; }
  .right-0-ns {
    right: 0; }
  .bottom-0-ns {
    bottom: 0; }
  .top-1-ns {
    top: 1rem; }
  .left-1-ns {
    left: 1rem; }
  .right-1-ns {
    right: 1rem; }
  .bottom-1-ns {
    bottom: 1rem; }
  .top-2-ns {
    top: 2rem; }
  .left-2-ns {
    left: 2rem; }
  .right-2-ns {
    right: 2rem; }
  .bottom-2-ns {
    bottom: 2rem; }
  .top--1-ns {
    top: -1rem; }
  .right--1-ns {
    right: -1rem; }
  .bottom--1-ns {
    bottom: -1rem; }
  .left--1-ns {
    left: -1rem; }
  .top--2-ns {
    top: -2rem; }
  .right--2-ns {
    right: -2rem; }
  .bottom--2-ns {
    bottom: -2rem; }
  .left--2-ns {
    left: -2rem; }
  .absolute--fill-ns {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; } }

@media (min-width: 48em) {
  .top-0-m {
    top: 0; }
  .left-0-m {
    left: 0; }
  .right-0-m {
    right: 0; }
  .bottom-0-m {
    bottom: 0; }
  .top-1-m {
    top: 1rem; }
  .left-1-m {
    left: 1rem; }
  .right-1-m {
    right: 1rem; }
  .bottom-1-m {
    bottom: 1rem; }
  .top-2-m {
    top: 2rem; }
  .left-2-m {
    left: 2rem; }
  .right-2-m {
    right: 2rem; }
  .bottom-2-m {
    bottom: 2rem; }
  .top--1-m {
    top: -1rem; }
  .right--1-m {
    right: -1rem; }
  .bottom--1-m {
    bottom: -1rem; }
  .left--1-m {
    left: -1rem; }
  .top--2-m {
    top: -2rem; }
  .right--2-m {
    right: -2rem; }
  .bottom--2-m {
    bottom: -2rem; }
  .left--2-m {
    left: -2rem; }
  .absolute--fill-m {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; } }

@media (min-width: 70em) {
  .top-0-l {
    top: 0; }
  .left-0-l {
    left: 0; }
  .right-0-l {
    right: 0; }
  .bottom-0-l {
    bottom: 0; }
  .top-1-l {
    top: 1rem; }
  .left-1-l {
    left: 1rem; }
  .right-1-l {
    right: 1rem; }
  .bottom-1-l {
    bottom: 1rem; }
  .top-2-l {
    top: 2rem; }
  .left-2-l {
    left: 2rem; }
  .right-2-l {
    right: 2rem; }
  .bottom-2-l {
    bottom: 2rem; }
  .top--1-l {
    top: -1rem; }
  .right--1-l {
    right: -1rem; }
  .bottom--1-l {
    bottom: -1rem; }
  .left--1-l {
    left: -1rem; }
  .top--2-l {
    top: -2rem; }
  .right--2-l {
    right: -2rem; }
  .bottom--2-l {
    bottom: -2rem; }
  .left--2-l {
    left: -2rem; }
  .absolute--fill-l {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; } }

/*

   CLEARFIX
   http://tachyons.io/docs/layout/clearfix/

*/
/* Nicolas Gallaghers Clearfix solution
   Ref: http://nicolasgallagher.com/micro-clearfix-hack/ */
.cf:before,
.cf:after {
  content: " ";
  display: table; }

.cf:after {
  clear: both; }

.cf {
  *zoom: 1; }

.cl {
  clear: left; }

.cr {
  clear: right; }

.cb {
  clear: both; }

.cn {
  clear: none; }

@media screen and (min-width: 30em) {
  .cl-ns {
    clear: left; }
  .cr-ns {
    clear: right; }
  .cb-ns {
    clear: both; }
  .cn-ns {
    clear: none; } }

@media (min-width: 48em) {
  .cl-m {
    clear: left; }
  .cr-m {
    clear: right; }
  .cb-m {
    clear: both; }
  .cn-m {
    clear: none; } }

@media (min-width: 70em) {
  .cl-l {
    clear: left; }
  .cr-l {
    clear: right; }
  .cb-l {
    clear: both; }
  .cn-l {
    clear: none; } }

/*

  FLEXBOX

  Media Query Extensions:
   -ns = not-small
   -m  = medium
   -l  = large

*/
.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

/* 1. Fix for Chrome 44 bug.
 * https://code.google.com/p/chromium/issues/detail?id=506893 */
.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  /* 1 */
  min-height: 0;
  /* 1 */ }

.flex-none {
  flex: none; }

.flex-column {
  flex-direction: column; }

.flex-row {
  flex-direction: row; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-nowrap {
  flex-wrap: nowrap; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.items-start {
  align-items: flex-start; }

.items-end {
  align-items: flex-end; }

.items-center {
  align-items: center; }

.items-baseline {
  align-items: baseline; }

.items-stretch {
  align-items: stretch; }

.self-start {
  align-self: flex-start; }

.self-end {
  align-self: flex-end; }

.self-center {
  align-self: center; }

.self-baseline {
  align-self: baseline; }

.self-stretch {
  align-self: stretch; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.justify-around {
  justify-content: space-around; }

.content-start {
  align-content: flex-start; }

.content-end {
  align-content: flex-end; }

.content-center {
  align-content: center; }

.content-between {
  align-content: space-between; }

.content-around {
  align-content: space-around; }

.content-stretch {
  align-content: stretch; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-last {
  order: 99999; }

.flex-grow-0 {
  flex-grow: 0; }

.flex-grow-1 {
  flex-grow: 1; }

.flex-shrink-0 {
  flex-shrink: 0; }

.flex-shrink-1 {
  flex-shrink: 1; }

@media screen and (min-width: 30em) {
  .flex-ns {
    display: flex; }
  .inline-flex-ns {
    display: inline-flex; }
  .flex-auto-ns {
    flex: 1 1 auto;
    min-width: 0;
    /* 1 */
    min-height: 0;
    /* 1 */ }
  .flex-none-ns {
    flex: none; }
  .flex-column-ns {
    flex-direction: column; }
  .flex-row-ns {
    flex-direction: row; }
  .flex-wrap-ns {
    flex-wrap: wrap; }
  .flex-nowrap-ns {
    flex-wrap: nowrap; }
  .flex-wrap-reverse-ns {
    flex-wrap: wrap-reverse; }
  .flex-column-reverse-ns {
    flex-direction: column-reverse; }
  .flex-row-reverse-ns {
    flex-direction: row-reverse; }
  .items-start-ns {
    align-items: flex-start; }
  .items-end-ns {
    align-items: flex-end; }
  .items-center-ns {
    align-items: center; }
  .items-baseline-ns {
    align-items: baseline; }
  .items-stretch-ns {
    align-items: stretch; }
  .self-start-ns {
    align-self: flex-start; }
  .self-end-ns {
    align-self: flex-end; }
  .self-center-ns {
    align-self: center; }
  .self-baseline-ns {
    align-self: baseline; }
  .self-stretch-ns {
    align-self: stretch; }
  .justify-start-ns {
    justify-content: flex-start; }
  .justify-end-ns {
    justify-content: flex-end; }
  .justify-center-ns {
    justify-content: center; }
  .justify-between-ns {
    justify-content: space-between; }
  .justify-around-ns {
    justify-content: space-around; }
  .content-start-ns {
    align-content: flex-start; }
  .content-end-ns {
    align-content: flex-end; }
  .content-center-ns {
    align-content: center; }
  .content-between-ns {
    align-content: space-between; }
  .content-around-ns {
    align-content: space-around; }
  .content-stretch-ns {
    align-content: stretch; }
  .order-0-ns {
    order: 0; }
  .order-1-ns {
    order: 1; }
  .order-2-ns {
    order: 2; }
  .order-3-ns {
    order: 3; }
  .order-4-ns {
    order: 4; }
  .order-5-ns {
    order: 5; }
  .order-6-ns {
    order: 6; }
  .order-7-ns {
    order: 7; }
  .order-8-ns {
    order: 8; }
  .order-last-ns {
    order: 99999; }
  .flex-grow-0-ns {
    flex-grow: 0; }
  .flex-grow-1-ns {
    flex-grow: 1; }
  .flex-shrink-0-ns {
    flex-shrink: 0; }
  .flex-shrink-1-ns {
    flex-shrink: 1; } }

@media (min-width: 48em) {
  .flex-m {
    display: flex; }
  .inline-flex-m {
    display: inline-flex; }
  .flex-auto-m {
    flex: 1 1 auto;
    min-width: 0;
    /* 1 */
    min-height: 0;
    /* 1 */ }
  .flex-none-m {
    flex: none; }
  .flex-column-m {
    flex-direction: column; }
  .flex-row-m {
    flex-direction: row; }
  .flex-wrap-m {
    flex-wrap: wrap; }
  .flex-nowrap-m {
    flex-wrap: nowrap; }
  .flex-wrap-reverse-m {
    flex-wrap: wrap-reverse; }
  .flex-column-reverse-m {
    flex-direction: column-reverse; }
  .flex-row-reverse-m {
    flex-direction: row-reverse; }
  .items-start-m {
    align-items: flex-start; }
  .items-end-m {
    align-items: flex-end; }
  .items-center-m {
    align-items: center; }
  .items-baseline-m {
    align-items: baseline; }
  .items-stretch-m {
    align-items: stretch; }
  .self-start-m {
    align-self: flex-start; }
  .self-end-m {
    align-self: flex-end; }
  .self-center-m {
    align-self: center; }
  .self-baseline-m {
    align-self: baseline; }
  .self-stretch-m {
    align-self: stretch; }
  .justify-start-m {
    justify-content: flex-start; }
  .justify-end-m {
    justify-content: flex-end; }
  .justify-center-m {
    justify-content: center; }
  .justify-between-m {
    justify-content: space-between; }
  .justify-around-m {
    justify-content: space-around; }
  .content-start-m {
    align-content: flex-start; }
  .content-end-m {
    align-content: flex-end; }
  .content-center-m {
    align-content: center; }
  .content-between-m {
    align-content: space-between; }
  .content-around-m {
    align-content: space-around; }
  .content-stretch-m {
    align-content: stretch; }
  .order-0-m {
    order: 0; }
  .order-1-m {
    order: 1; }
  .order-2-m {
    order: 2; }
  .order-3-m {
    order: 3; }
  .order-4-m {
    order: 4; }
  .order-5-m {
    order: 5; }
  .order-6-m {
    order: 6; }
  .order-7-m {
    order: 7; }
  .order-8-m {
    order: 8; }
  .order-last-m {
    order: 99999; }
  .flex-grow-0-m {
    flex-grow: 0; }
  .flex-grow-1-m {
    flex-grow: 1; }
  .flex-shrink-0-m {
    flex-shrink: 0; }
  .flex-shrink-1-m {
    flex-shrink: 1; } }

@media (min-width: 70em) {
  .flex-l {
    display: flex; }
  .inline-flex-l {
    display: inline-flex; }
  .flex-auto-l {
    flex: 1 1 auto;
    min-width: 0;
    /* 1 */
    min-height: 0;
    /* 1 */ }
  .flex-none-l {
    flex: none; }
  .flex-column-l {
    flex-direction: column; }
  .flex-row-l {
    flex-direction: row; }
  .flex-wrap-l {
    flex-wrap: wrap; }
  .flex-nowrap-l {
    flex-wrap: nowrap; }
  .flex-wrap-reverse-l {
    flex-wrap: wrap-reverse; }
  .flex-column-reverse-l {
    flex-direction: column-reverse; }
  .flex-row-reverse-l {
    flex-direction: row-reverse; }
  .items-start-l {
    align-items: flex-start; }
  .items-end-l {
    align-items: flex-end; }
  .items-center-l {
    align-items: center; }
  .items-baseline-l {
    align-items: baseline; }
  .items-stretch-l {
    align-items: stretch; }
  .self-start-l {
    align-self: flex-start; }
  .self-end-l {
    align-self: flex-end; }
  .self-center-l {
    align-self: center; }
  .self-baseline-l {
    align-self: baseline; }
  .self-stretch-l {
    align-self: stretch; }
  .justify-start-l {
    justify-content: flex-start; }
  .justify-end-l {
    justify-content: flex-end; }
  .justify-center-l {
    justify-content: center; }
  .justify-between-l {
    justify-content: space-between; }
  .justify-around-l {
    justify-content: space-around; }
  .content-start-l {
    align-content: flex-start; }
  .content-end-l {
    align-content: flex-end; }
  .content-center-l {
    align-content: center; }
  .content-between-l {
    align-content: space-between; }
  .content-around-l {
    align-content: space-around; }
  .content-stretch-l {
    align-content: stretch; }
  .order-0-l {
    order: 0; }
  .order-1-l {
    order: 1; }
  .order-2-l {
    order: 2; }
  .order-3-l {
    order: 3; }
  .order-4-l {
    order: 4; }
  .order-5-l {
    order: 5; }
  .order-6-l {
    order: 6; }
  .order-7-l {
    order: 7; }
  .order-8-l {
    order: 8; }
  .order-last-l {
    order: 99999; }
  .flex-grow-0-l {
    flex-grow: 0; }
  .flex-grow-1-l {
    flex-grow: 1; }
  .flex-shrink-0-l {
    flex-shrink: 0; }
  .flex-shrink-1-l {
    flex-shrink: 1; } }

/*

   DISPLAY
   Docs: http://tachyons.io/docs/layout/display

   Base:
    d = display

   Modifiers:
    n     = none
    b     = block
    ib    = inline-block
    it    = inline-table
    t     = table
    tc    = table-cell
    tr    = table-row
    tcol  = table-column
    tcolg = table-column-group

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.dn {
  display: none; }

.di {
  display: inline; }

.db {
  display: block; }

.dib {
  display: inline-block; }

.dit {
  display: inline-table; }

.dt {
  display: table; }

.dtc {
  display: table-cell; }

.dt-row {
  display: table-row; }

.dt-row-group {
  display: table-row-group; }

.dt-column {
  display: table-column; }

.dt-column-group {
  display: table-column-group; }

/*
  This will set table to full width and then
  all cells will be equal width
*/
.dt--fixed {
  table-layout: fixed;
  width: 100%; }

@media screen and (min-width: 30em) {
  .dn-ns {
    display: none; }
  .di-ns {
    display: inline; }
  .db-ns {
    display: block; }
  .dib-ns {
    display: inline-block; }
  .dit-ns {
    display: inline-table; }
  .dt-ns {
    display: table; }
  .dtc-ns {
    display: table-cell; }
  .dt-row-ns {
    display: table-row; }
  .dt-row-group-ns {
    display: table-row-group; }
  .dt-column-ns {
    display: table-column; }
  .dt-column-group-ns {
    display: table-column-group; }
  .dt--fixed-ns {
    table-layout: fixed;
    width: 100%; } }

@media (min-width: 48em) {
  .dn-m {
    display: none; }
  .di-m {
    display: inline; }
  .db-m {
    display: block; }
  .dib-m {
    display: inline-block; }
  .dit-m {
    display: inline-table; }
  .dt-m {
    display: table; }
  .dtc-m {
    display: table-cell; }
  .dt-row-m {
    display: table-row; }
  .dt-row-group-m {
    display: table-row-group; }
  .dt-column-m {
    display: table-column; }
  .dt-column-group-m {
    display: table-column-group; }
  .dt--fixed-m {
    table-layout: fixed;
    width: 100%; } }

@media (min-width: 70em) {
  .dn-l {
    display: none; }
  .di-l {
    display: inline; }
  .db-l {
    display: block; }
  .dib-l {
    display: inline-block; }
  .dit-l {
    display: inline-table; }
  .dt-l {
    display: table; }
  .dtc-l {
    display: table-cell; }
  .dt-row-l {
    display: table-row; }
  .dt-row-group-l {
    display: table-row-group; }
  .dt-column-l {
    display: table-column; }
  .dt-column-group-l {
    display: table-column-group; }
  .dt--fixed-l {
    table-layout: fixed;
    width: 100%; } }

/*

   FLOATS
   http://tachyons.io/docs/layout/floats/

   1. Floated elements are automatically rendered as block level elements.
      Setting floats to display inline will fix the double margin bug in
      ie6. You know... just in case.

   2. Don't forget to clearfix your floats with .cf

   Base:
     f = float

   Modifiers:
     l = left
     r = right
     n = none

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.fl {
  float: left;
  _display: inline; }

.fr {
  float: right;
  _display: inline; }

.fn {
  float: none; }

@media screen and (min-width: 30em) {
  .fl-ns {
    float: left;
    _display: inline; }
  .fr-ns {
    float: right;
    _display: inline; }
  .fn-ns {
    float: none; } }

@media (min-width: 48em) {
  .fl-m {
    float: left;
    _display: inline; }
  .fr-m {
    float: right;
    _display: inline; }
  .fn-m {
    float: none; } }

@media (min-width: 70em) {
  .fl-l {
    float: left;
    _display: inline; }
  .fr-l {
    float: right;
    _display: inline; }
  .fn-l {
    float: none; } }

/*

   FONT FAMILY GROUPS
   Docs: http://tachyons.io/docs/typography/font-family/

*/
.sans-serif {
  font-family: -apple-system, BlinkMacSystemFont, "avenir next", avenir, helvetica, "helvetica neue", ubuntu, roboto, noto, "segoe ui", arial, sans-serif; }

.serif {
  font-family: georgia, serif; }

.system-sans-serif {
  font-family: sans-serif; }

.system-serif {
  font-family: serif; }

/* Monospaced Typefaces (for code) */
/* From http://cssfontstack.com */
code, .code {
  font-family: Consolas, monaco, monospace; }

.courier {
  font-family: 'Courier Next', courier, monospace; }

/* Sans-Serif Typefaces */
.helvetica {
  font-family: 'helvetica neue', helvetica, sans-serif; }

.avenir {
  font-family: 'avenir next', avenir, sans-serif; }

/* Serif Typefaces */
.athelas {
  font-family: athelas, georgia, serif; }

.georgia {
  font-family: georgia, serif; }

.times {
  font-family: times, serif; }

.bodoni {
  font-family: "Bodoni MT", serif; }

.calisto {
  font-family: "Calisto MT", serif; }

.garamond {
  font-family: garamond, serif; }

.baskerville {
  font-family: baskerville, serif; }

/*

   FONT STYLE
   Docs: http://tachyons.io/docs/typography/font-style/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.i {
  font-style: italic; }

.fs-normal {
  font-style: normal; }

@media screen and (min-width: 30em) {
  .i-ns {
    font-style: italic; }
  .fs-normal-ns {
    font-style: normal; } }

@media (min-width: 48em) {
  .i-m {
    font-style: italic; }
  .fs-normal-m {
    font-style: normal; } }

@media (min-width: 70em) {
  .i-l {
    font-style: italic; }
  .fs-normal-l {
    font-style: normal; } }

/*

   FONT WEIGHT
   Docs: http://tachyons.io/docs/typography/font-weight/

   Base
     fw = font-weight

   Modifiers:
     1 = literal value 100
     2 = literal value 200
     3 = literal value 300
     4 = literal value 400
     5 = literal value 500
     6 = literal value 600
     7 = literal value 700
     8 = literal value 800
     9 = literal value 900

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.normal {
  font-weight: normal; }

.b {
  font-weight: bold; }

.fw1 {
  font-weight: 100; }

.fw2 {
  font-weight: 200; }

.fw3 {
  font-weight: 300; }

.fw4 {
  font-weight: 400; }

.fw5 {
  font-weight: 500; }

.fw6 {
  font-weight: 600; }

.fw7 {
  font-weight: 700; }

.fw8 {
  font-weight: 800; }

.fw9 {
  font-weight: 900; }

@media screen and (min-width: 30em) {
  .normal-ns {
    font-weight: normal; }
  .b-ns {
    font-weight: bold; }
  .fw1-ns {
    font-weight: 100; }
  .fw2-ns {
    font-weight: 200; }
  .fw3-ns {
    font-weight: 300; }
  .fw4-ns {
    font-weight: 400; }
  .fw5-ns {
    font-weight: 500; }
  .fw6-ns {
    font-weight: 600; }
  .fw7-ns {
    font-weight: 700; }
  .fw8-ns {
    font-weight: 800; }
  .fw9-ns {
    font-weight: 900; } }

@media (min-width: 48em) {
  .normal-m {
    font-weight: normal; }
  .b-m {
    font-weight: bold; }
  .fw1-m {
    font-weight: 100; }
  .fw2-m {
    font-weight: 200; }
  .fw3-m {
    font-weight: 300; }
  .fw4-m {
    font-weight: 400; }
  .fw5-m {
    font-weight: 500; }
  .fw6-m {
    font-weight: 600; }
  .fw7-m {
    font-weight: 700; }
  .fw8-m {
    font-weight: 800; }
  .fw9-m {
    font-weight: 900; } }

@media (min-width: 70em) {
  .normal-l {
    font-weight: normal; }
  .b-l {
    font-weight: bold; }
  .fw1-l {
    font-weight: 100; }
  .fw2-l {
    font-weight: 200; }
  .fw3-l {
    font-weight: 300; }
  .fw4-l {
    font-weight: 400; }
  .fw5-l {
    font-weight: 500; }
  .fw6-l {
    font-weight: 600; }
  .fw7-l {
    font-weight: 700; }
  .fw8-l {
    font-weight: 800; }
  .fw9-l {
    font-weight: 900; } }

/*

   FORMS
   
*/
.input-reset {
  -webkit-appearance: none;
  -moz-appearance: none; }

.button-reset::-moz-focus-inner,
.input-reset::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*

   HEIGHTS
   Docs: http://tachyons.io/docs/layout/heights/

   Base:
     h = height
     min-h = min-height
     min-vh = min-height vertical screen height
     vh = vertical screen height

   Modifiers
     1 = 1st step in height scale
     2 = 2nd step in height scale
     3 = 3rd step in height scale
     4 = 4th step in height scale
     5 = 5th step in height scale

     -25   = literal value 25%
     -50   = literal value 50%
     -75   = literal value 75%
     -100  = literal value 100%

     -auto = string value of auto
     -inherit = string value of inherit

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
/* Height Scale */
.h1 {
  height: 1rem; }

.h2 {
  height: 2rem; }

.h3 {
  height: 4rem; }

.h4 {
  height: 8rem; }

.h5 {
  height: 16rem; }

/* Height Percentages - Based off of height of parent */
.h-25 {
  height: 25%; }

.h-50 {
  height: 50%; }

.h-75 {
  height: 75%; }

.h-100 {
  height: 100%; }

.min-h-100 {
  min-height: 100%; }

/* Screen Height Percentage */
.vh-25 {
  height: 25vh; }

.vh-50 {
  height: 50vh; }

.vh-75 {
  height: 75vh; }

.vh-100 {
  height: 100vh; }

.min-vh-100 {
  min-height: 100vh; }

/* String Properties */
.h-auto {
  height: auto; }

.h-inherit {
  height: inherit; }

@media screen and (min-width: 30em) {
  .h1-ns {
    height: 1rem; }
  .h2-ns {
    height: 2rem; }
  .h3-ns {
    height: 4rem; }
  .h4-ns {
    height: 8rem; }
  .h5-ns {
    height: 16rem; }
  .h-25-ns {
    height: 25%; }
  .h-50-ns {
    height: 50%; }
  .h-75-ns {
    height: 75%; }
  .h-100-ns {
    height: 100%; }
  .min-h-100-ns {
    min-height: 100%; }
  .vh-25-ns {
    height: 25vh; }
  .vh-50-ns {
    height: 50vh; }
  .vh-75-ns {
    height: 75vh; }
  .vh-100-ns {
    height: 100vh; }
  .min-vh-100-ns {
    min-height: 100vh; }
  .h-auto-ns {
    height: auto; }
  .h-inherit-ns {
    height: inherit; } }

@media (min-width: 48em) {
  .h1-m {
    height: 1rem; }
  .h2-m {
    height: 2rem; }
  .h3-m {
    height: 4rem; }
  .h4-m {
    height: 8rem; }
  .h5-m {
    height: 16rem; }
  .h-25-m {
    height: 25%; }
  .h-50-m {
    height: 50%; }
  .h-75-m {
    height: 75%; }
  .h-100-m {
    height: 100%; }
  .min-h-100-m {
    min-height: 100%; }
  .vh-25-m {
    height: 25vh; }
  .vh-50-m {
    height: 50vh; }
  .vh-75-m {
    height: 75vh; }
  .vh-100-m {
    height: 100vh; }
  .min-vh-100-m {
    min-height: 100vh; }
  .h-auto-m {
    height: auto; }
  .h-inherit-m {
    height: inherit; } }

@media (min-width: 70em) {
  .h1-l {
    height: 1rem; }
  .h2-l {
    height: 2rem; }
  .h3-l {
    height: 4rem; }
  .h4-l {
    height: 8rem; }
  .h5-l {
    height: 16rem; }
  .h-25-l {
    height: 25%; }
  .h-50-l {
    height: 50%; }
  .h-75-l {
    height: 75%; }
  .h-100-l {
    height: 100%; }
  .min-h-100-l {
    min-height: 100%; }
  .vh-25-l {
    height: 25vh; }
  .vh-50-l {
    height: 50vh; }
  .vh-75-l {
    height: 75vh; }
  .vh-100-l {
    height: 100vh; }
  .min-vh-100-l {
    min-height: 100vh; }
  .h-auto-l {
    height: auto; }
  .h-inherit-l {
    height: inherit; } }

/*

   LETTER SPACING
   Docs: http://tachyons.io/docs/typography/tracking/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.tracked {
  letter-spacing: 0.1em; }

.tracked-tight {
  letter-spacing: -0.05em; }

.tracked-mega {
  letter-spacing: 0.25em; }

@media screen and (min-width: 30em) {
  .tracked-ns {
    letter-spacing: 0.1em; }
  .tracked-tight-ns {
    letter-spacing: -0.05em; }
  .tracked-mega-ns {
    letter-spacing: 0.25em; } }

@media (min-width: 48em) {
  .tracked-m {
    letter-spacing: 0.1em; }
  .tracked-tight-m {
    letter-spacing: -0.05em; }
  .tracked-mega-m {
    letter-spacing: 0.25em; } }

@media (min-width: 70em) {
  .tracked-l {
    letter-spacing: 0.1em; }
  .tracked-tight-l {
    letter-spacing: -0.05em; }
  .tracked-mega-l {
    letter-spacing: 0.25em; } }

/*

   LINE HEIGHT / LEADING
   Docs: http://tachyons.io/docs/typography/line-height

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.lh-solid {
  line-height: 1; }

.lh-title {
  line-height: 1.25; }

.lh-copy {
  line-height: 1.5; }

@media screen and (min-width: 30em) {
  .lh-solid-ns {
    line-height: 1; }
  .lh-title-ns {
    line-height: 1.25; }
  .lh-copy-ns {
    line-height: 1.5; } }

@media (min-width: 48em) {
  .lh-solid-m {
    line-height: 1; }
  .lh-title-m {
    line-height: 1.25; }
  .lh-copy-m {
    line-height: 1.5; } }

@media (min-width: 70em) {
  .lh-solid-l {
    line-height: 1; }
  .lh-title-l {
    line-height: 1.25; }
  .lh-copy-l {
    line-height: 1.5; } }

/*

   LINKS
   Docs: http://tachyons.io/docs/elements/links/

*/
.link {
  text-decoration: none;
  -webkit-transition: color .15s ease-in;
  transition: color .15s ease-in; }

.link:link,
.link:visited {
  -webkit-transition: color .15s ease-in;
  transition: color .15s ease-in; }

.link:hover {
  -webkit-transition: color .15s ease-in;
  transition: color .15s ease-in; }

.link:active {
  -webkit-transition: color .15s ease-in;
  transition: color .15s ease-in; }

.link:focus {
  -webkit-transition: color .15s ease-in;
  transition: color .15s ease-in;
  outline: 1px dotted currentColor; }

/*

   LISTS
   http://tachyons.io/docs/elements/lists/

*/
.list {
  list-style-type: none; }

/*

   MAX WIDTHS
   Docs: http://tachyons.io/docs/layout/max-widths/

   Base:
     mw = max-width

   Modifiers
     1 = 1st step in width scale
     2 = 2nd step in width scale
     3 = 3rd step in width scale
     4 = 4th step in width scale
     5 = 5th step in width scale
     6 = 6st step in width scale
     7 = 7nd step in width scale
     8 = 8rd step in width scale
     9 = 9th step in width scale

     -100 = literal value 100%

     -none  = string value none


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
/* Max Width Percentages */
.mw-100 {
  max-width: 100%; }

/* Max Width Scale */
.mw1 {
  max-width: 1rem; }

.mw2 {
  max-width: 2rem; }

.mw3 {
  max-width: 4rem; }

.mw4 {
  max-width: 8rem; }

.mw5 {
  max-width: 16rem; }

.mw6 {
  max-width: 32rem; }

.mw7 {
  max-width: 48rem; }

.mw8 {
  max-width: 64rem; }

.mw9 {
  max-width: 96rem; }

/* Max Width String Properties */
.mw-none {
  max-width: none; }

@media screen and (min-width: 30em) {
  .mw-100-ns {
    max-width: 100%; }
  .mw1-ns {
    max-width: 1rem; }
  .mw2-ns {
    max-width: 2rem; }
  .mw3-ns {
    max-width: 4rem; }
  .mw4-ns {
    max-width: 8rem; }
  .mw5-ns {
    max-width: 16rem; }
  .mw6-ns {
    max-width: 32rem; }
  .mw7-ns {
    max-width: 48rem; }
  .mw8-ns {
    max-width: 64rem; }
  .mw9-ns {
    max-width: 96rem; }
  .mw-none-ns {
    max-width: none; } }

@media (min-width: 48em) {
  .mw-100-m {
    max-width: 100%; }
  .mw1-m {
    max-width: 1rem; }
  .mw2-m {
    max-width: 2rem; }
  .mw3-m {
    max-width: 4rem; }
  .mw4-m {
    max-width: 8rem; }
  .mw5-m {
    max-width: 16rem; }
  .mw6-m {
    max-width: 32rem; }
  .mw7-m {
    max-width: 48rem; }
  .mw8-m {
    max-width: 64rem; }
  .mw9-m {
    max-width: 96rem; }
  .mw-none-m {
    max-width: none; } }

@media (min-width: 70em) {
  .mw-100-l {
    max-width: 100%; }
  .mw1-l {
    max-width: 1rem; }
  .mw2-l {
    max-width: 2rem; }
  .mw3-l {
    max-width: 4rem; }
  .mw4-l {
    max-width: 8rem; }
  .mw5-l {
    max-width: 16rem; }
  .mw6-l {
    max-width: 32rem; }
  .mw7-l {
    max-width: 48rem; }
  .mw8-l {
    max-width: 64rem; }
  .mw9-l {
    max-width: 96rem; }
  .mw-none-l {
    max-width: none; } }

/*

    OVERFLOW

    Media Query Extensions:
      -ns = not-small
      -m  = medium
      -l  = large

 */
.overflow-visible {
  overflow: visible; }

.overflow-hidden {
  overflow: hidden; }

.overflow-scroll {
  overflow: scroll; }

.overflow-auto {
  overflow: auto; }

.overflow-x-visible {
  overflow-x: visible; }

.overflow-x-hidden {
  overflow-x: hidden; }

.overflow-x-scroll {
  overflow-x: scroll; }

.overflow-x-auto {
  overflow-x: auto; }

.overflow-y-visible {
  overflow-y: visible; }

.overflow-y-hidden {
  overflow-y: hidden; }

.overflow-y-scroll {
  overflow-y: scroll; }

.overflow-y-auto {
  overflow-y: auto; }

@media screen and (min-width: 30em) {
  .overflow-visible-ns {
    overflow: visible; }
  .overflow-hidden-ns {
    overflow: hidden; }
  .overflow-scroll-ns {
    overflow: scroll; }
  .overflow-auto-ns {
    overflow: auto; }
  .overflow-x-visible-ns {
    overflow-x: visible; }
  .overflow-x-hidden-ns {
    overflow-x: hidden; }
  .overflow-x-scroll-ns {
    overflow-x: scroll; }
  .overflow-x-auto-ns {
    overflow-x: auto; }
  .overflow-y-visible-ns {
    overflow-y: visible; }
  .overflow-y-hidden-ns {
    overflow-y: hidden; }
  .overflow-y-scroll-ns {
    overflow-y: scroll; }
  .overflow-y-auto-ns {
    overflow-y: auto; } }

@media (min-width: 48em) {
  .overflow-visible-m {
    overflow: visible; }
  .overflow-hidden-m {
    overflow: hidden; }
  .overflow-scroll-m {
    overflow: scroll; }
  .overflow-auto-m {
    overflow: auto; }
  .overflow-x-visible-m {
    overflow-x: visible; }
  .overflow-x-hidden-m {
    overflow-x: hidden; }
  .overflow-x-scroll-m {
    overflow-x: scroll; }
  .overflow-x-auto-m {
    overflow-x: auto; }
  .overflow-y-visible-m {
    overflow-y: visible; }
  .overflow-y-hidden-m {
    overflow-y: hidden; }
  .overflow-y-scroll-m {
    overflow-y: scroll; }
  .overflow-y-auto-m {
    overflow-y: auto; } }

@media (min-width: 70em) {
  .overflow-visible-l {
    overflow: visible; }
  .overflow-hidden-l {
    overflow: hidden; }
  .overflow-scroll-l {
    overflow: scroll; }
  .overflow-auto-l {
    overflow: auto; }
  .overflow-x-visible-l {
    overflow-x: visible; }
  .overflow-x-hidden-l {
    overflow-x: hidden; }
  .overflow-x-scroll-l {
    overflow-x: scroll; }
  .overflow-x-auto-l {
    overflow-x: auto; }
  .overflow-y-visible-l {
    overflow-y: visible; }
  .overflow-y-hidden-l {
    overflow-y: hidden; }
  .overflow-y-scroll-l {
    overflow-y: scroll; }
  .overflow-y-auto-l {
    overflow-y: auto; } }

/*

   POSITIONING
   Docs: http://tachyons.io/docs/layout/position/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.static {
  position: static; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.fixed {
  position: fixed; }

@media screen and (min-width: 30em) {
  .static-ns {
    position: static; }
  .relative-ns {
    position: relative; }
  .absolute-ns {
    position: absolute; }
  .fixed-ns {
    position: fixed; } }

@media (min-width: 48em) {
  .static-m {
    position: static; }
  .relative-m {
    position: relative; }
  .absolute-m {
    position: absolute; }
  .fixed-m {
    position: fixed; } }

@media (min-width: 70em) {
  .static-l {
    position: static; }
  .relative-l {
    position: relative; }
  .absolute-l {
    position: absolute; }
  .fixed-l {
    position: fixed; } }

/*

    OPACITY
    Docs: http://tachyons.io/docs/themes/opacity/

*/
.o-100 {
  opacity: 1; }

.o-90 {
  opacity: .9; }

.o-80 {
  opacity: .8; }

.o-70 {
  opacity: .7; }

.o-60 {
  opacity: .6; }

.o-50 {
  opacity: .5; }

.o-40 {
  opacity: .4; }

.o-30 {
  opacity: .3; }

.o-20 {
  opacity: .2; }

.o-10 {
  opacity: .1; }

.o-05 {
  opacity: .05; }

.o-025 {
  opacity: .025; }

.o-0 {
  opacity: 0; }

/*

   ROTATIONS

*/
.rotate-45 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.rotate-135 {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg); }

.rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.rotate-225 {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg); }

.rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.rotate-315 {
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg); }

@media screen and (min-width: 30em) {
  .rotate-45-ns {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .rotate-90-ns {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .rotate-135-ns {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  .rotate-180-ns {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .rotate-225-ns {
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg); }
  .rotate-270-ns {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  .rotate-315-ns {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg); } }

@media (min-width: 48em) {
  .rotate-45-m {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .rotate-90-m {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .rotate-135-m {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  .rotate-180-m {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .rotate-225-m {
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg); }
  .rotate-270-m {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  .rotate-315-m {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg); } }

@media (min-width: 70em) {
  .rotate-45-l {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .rotate-90-l {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .rotate-135-l {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  .rotate-180-l {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .rotate-225-l {
    -webkit-transform: rotate(225deg);
            transform: rotate(225deg); }
  .rotate-270-l {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  .rotate-315-l {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg); } }

/*

   SKINS
   Docs: http://tachyons.io/docs/themes/skins/

   Classes for setting foreground and background colors on elements.
   If you haven't declared a border color, but set border on an element, it will
   be set to the current text color.

*/
/* Text colors */
.black-90 {
  color: rgba(0, 0, 0, 0.9); }

.black-80 {
  color: rgba(0, 0, 0, 0.8); }

.black-70 {
  color: rgba(0, 0, 0, 0.7); }

.black-60 {
  color: rgba(0, 0, 0, 0.6); }

.black-50 {
  color: rgba(0, 0, 0, 0.5); }

.black-40 {
  color: rgba(0, 0, 0, 0.4); }

.black-30 {
  color: rgba(0, 0, 0, 0.3); }

.black-20 {
  color: rgba(0, 0, 0, 0.2); }

.black-10 {
  color: rgba(0, 0, 0, 0.1); }

.black-05 {
  color: rgba(0, 0, 0, 0.05); }

.white-90 {
  color: rgba(255, 255, 255, 0.9); }

.white-80 {
  color: rgba(255, 255, 255, 0.8); }

.white-70 {
  color: rgba(255, 255, 255, 0.7); }

.white-60 {
  color: rgba(255, 255, 255, 0.6); }

.white-50 {
  color: rgba(255, 255, 255, 0.5); }

.white-40 {
  color: rgba(255, 255, 255, 0.4); }

.white-30 {
  color: rgba(255, 255, 255, 0.3); }

.white-20 {
  color: rgba(255, 255, 255, 0.2); }

.white-10 {
  color: rgba(255, 255, 255, 0.1); }

.black {
  color: #000; }

.near-black {
  color: #111; }

.dark-gray {
  color: #333; }

.mid-gray {
  color: #555; }

.gray {
  color: #777; }

.silver {
  color: #999; }

.light-silver {
  color: #aaa; }

.moon-gray {
  color: #ccc; }

.light-gray {
  color: #eee; }

.near-white {
  color: #f4f4f4; }

.white {
  color: #fff; }

.dark-red {
  color: #e7040f; }

.red {
  color: #ff4136; }

.light-red {
  color: #ff725c; }

.orange {
  color: #ff6300; }

.gold {
  color: #ffb700; }

.yellow {
  color: #ffd700; }

.light-yellow {
  color: #fbf1a9; }

.purple {
  color: #5e2ca5; }

.light-purple {
  color: #a463f2; }

.dark-pink {
  color: #d5008f; }

.hot-pink {
  color: #ff41b4; }

.pink {
  color: #ff80cc; }

.light-pink {
  color: #ffa3d7; }

.dark-green {
  color: #137752; }

.green {
  color: #19a974; }

.light-green {
  color: #9eebcf; }

.navy {
  color: #001b44; }

.dark-blue {
  color: #00449e; }

.blue {
  color: #357edd; }

.light-blue {
  color: #96ccff; }

.lightest-blue {
  color: #cdecff; }

.washed-blue {
  color: #f6fffe; }

.washed-green {
  color: #e8fdf5; }

.washed-yellow {
  color: #fffceb; }

.washed-red {
  color: #ffdfdf; }

.color-inherit {
  color: inherit; }

.bg-black-90 {
  background-color: rgba(0, 0, 0, 0.9); }

.bg-black-80 {
  background-color: rgba(0, 0, 0, 0.8); }

.bg-black-70 {
  background-color: rgba(0, 0, 0, 0.7); }

.bg-black-60 {
  background-color: rgba(0, 0, 0, 0.6); }

.bg-black-50 {
  background-color: rgba(0, 0, 0, 0.5); }

.bg-black-40 {
  background-color: rgba(0, 0, 0, 0.4); }

.bg-black-30 {
  background-color: rgba(0, 0, 0, 0.3); }

.bg-black-20 {
  background-color: rgba(0, 0, 0, 0.2); }

.bg-black-10 {
  background-color: rgba(0, 0, 0, 0.1); }

.bg-black-05 {
  background-color: rgba(0, 0, 0, 0.05); }

.bg-white-90 {
  background-color: rgba(255, 255, 255, 0.9); }

.bg-white-80 {
  background-color: rgba(255, 255, 255, 0.8); }

.bg-white-70 {
  background-color: rgba(255, 255, 255, 0.7); }

.bg-white-60 {
  background-color: rgba(255, 255, 255, 0.6); }

.bg-white-50 {
  background-color: rgba(255, 255, 255, 0.5); }

.bg-white-40 {
  background-color: rgba(255, 255, 255, 0.4); }

.bg-white-30 {
  background-color: rgba(255, 255, 255, 0.3); }

.bg-white-20 {
  background-color: rgba(255, 255, 255, 0.2); }

.bg-white-10 {
  background-color: rgba(255, 255, 255, 0.1); }

/* Background colors */
.bg-black {
  background-color: #000; }

.bg-near-black {
  background-color: #111; }

.bg-dark-gray {
  background-color: #333; }

.bg-mid-gray {
  background-color: #555; }

.bg-gray {
  background-color: #777; }

.bg-silver {
  background-color: #999; }

.bg-light-silver {
  background-color: #aaa; }

.bg-moon-gray {
  background-color: #ccc; }

.bg-light-gray {
  background-color: #eee; }

.bg-near-white {
  background-color: #f4f4f4; }

.bg-white {
  background-color: #fff; }

.bg-transparent {
  background-color: transparent; }

.bg-dark-red {
  background-color: #e7040f; }

.bg-red {
  background-color: #ff4136; }

.bg-light-red {
  background-color: #ff725c; }

.bg-orange {
  background-color: #ff6300; }

.bg-gold {
  background-color: #ffb700; }

.bg-yellow {
  background-color: #ffd700; }

.bg-light-yellow {
  background-color: #fbf1a9; }

.bg-purple {
  background-color: #5e2ca5; }

.bg-light-purple {
  background-color: #a463f2; }

.bg-dark-pink {
  background-color: #d5008f; }

.bg-hot-pink {
  background-color: #ff41b4; }

.bg-pink {
  background-color: #ff80cc; }

.bg-light-pink {
  background-color: #ffa3d7; }

.bg-dark-green {
  background-color: #137752; }

.bg-green {
  background-color: #19a974; }

.bg-light-green {
  background-color: #9eebcf; }

.bg-navy {
  background-color: #001b44; }

.bg-dark-blue {
  background-color: #00449e; }

.bg-blue {
  background-color: #357edd; }

.bg-light-blue {
  background-color: #96ccff; }

.bg-lightest-blue {
  background-color: #cdecff; }

.bg-washed-blue {
  background-color: #f6fffe; }

.bg-washed-green {
  background-color: #e8fdf5; }

.bg-washed-yellow {
  background-color: #fffceb; }

.bg-washed-red {
  background-color: #ffdfdf; }

.bg-inherit {
  background-color: inherit; }

/*

   SKINS:PSEUDO

   Customize the color of an element when
   it is focused or hovered over.

 */
.hover-black:hover,
.hover-black:focus {
  color: #000; }

.hover-near-black:hover,
.hover-near-black:focus {
  color: #111; }

.hover-dark-gray:hover,
.hover-dark-gray:focus {
  color: #333; }

.hover-mid-gray:hover,
.hover-mid-gray:focus {
  color: #555; }

.hover-gray:hover,
.hover-gray:focus {
  color: #777; }

.hover-silver:hover,
.hover-silver:focus {
  color: #999; }

.hover-light-silver:hover,
.hover-light-silver:focus {
  color: #aaa; }

.hover-moon-gray:hover,
.hover-moon-gray:focus {
  color: #ccc; }

.hover-light-gray:hover,
.hover-light-gray:focus {
  color: #eee; }

.hover-near-white:hover,
.hover-near-white:focus {
  color: #f4f4f4; }

.hover-white:hover,
.hover-white:focus {
  color: #fff; }

.hover-black-90:hover,
.hover-black-90:focus {
  color: rgba(0, 0, 0, 0.9); }

.hover-black-80:hover,
.hover-black-80:focus {
  color: rgba(0, 0, 0, 0.8); }

.hover-black-70:hover,
.hover-black-70:focus {
  color: rgba(0, 0, 0, 0.7); }

.hover-black-60:hover,
.hover-black-60:focus {
  color: rgba(0, 0, 0, 0.6); }

.hover-black-50:hover,
.hover-black-50:focus {
  color: rgba(0, 0, 0, 0.5); }

.hover-black-40:hover,
.hover-black-40:focus {
  color: rgba(0, 0, 0, 0.4); }

.hover-black-30:hover,
.hover-black-30:focus {
  color: rgba(0, 0, 0, 0.3); }

.hover-black-20:hover,
.hover-black-20:focus {
  color: rgba(0, 0, 0, 0.2); }

.hover-black-10:hover,
.hover-black-10:focus {
  color: rgba(0, 0, 0, 0.1); }

.hover-white-90:hover,
.hover-white-90:focus {
  color: rgba(255, 255, 255, 0.9); }

.hover-white-80:hover,
.hover-white-80:focus {
  color: rgba(255, 255, 255, 0.8); }

.hover-white-70:hover,
.hover-white-70:focus {
  color: rgba(255, 255, 255, 0.7); }

.hover-white-60:hover,
.hover-white-60:focus {
  color: rgba(255, 255, 255, 0.6); }

.hover-white-50:hover,
.hover-white-50:focus {
  color: rgba(255, 255, 255, 0.5); }

.hover-white-40:hover,
.hover-white-40:focus {
  color: rgba(255, 255, 255, 0.4); }

.hover-white-30:hover,
.hover-white-30:focus {
  color: rgba(255, 255, 255, 0.3); }

.hover-white-20:hover,
.hover-white-20:focus {
  color: rgba(255, 255, 255, 0.2); }

.hover-white-10:hover,
.hover-white-10:focus {
  color: rgba(255, 255, 255, 0.1); }

.hover-inherit:hover,
.hover-inherit:focus {
  color: inherit; }

.hover-bg-black:hover,
.hover-bg-black:focus {
  background-color: #000; }

.hover-bg-near-black:hover,
.hover-bg-near-black:focus {
  background-color: #111; }

.hover-bg-dark-gray:hover,
.hover-bg-dark-gray:focus {
  background-color: #333; }

.hover-bg-mid-gray:hover,
.hover-bg-mid-gray:focus {
  background-color: #555; }

.hover-bg-gray:hover,
.hover-bg-gray:focus {
  background-color: #777; }

.hover-bg-silver:hover,
.hover-bg-silver:focus {
  background-color: #999; }

.hover-bg-light-silver:hover,
.hover-bg-light-silver:focus {
  background-color: #aaa; }

.hover-bg-moon-gray:hover,
.hover-bg-moon-gray:focus {
  background-color: #ccc; }

.hover-bg-light-gray:hover,
.hover-bg-light-gray:focus {
  background-color: #eee; }

.hover-bg-near-white:hover,
.hover-bg-near-white:focus {
  background-color: #f4f4f4; }

.hover-bg-white:hover,
.hover-bg-white:focus {
  background-color: #fff; }

.hover-bg-transparent:hover,
.hover-bg-transparent:focus {
  background-color: transparent; }

.hover-bg-black-90:hover,
.hover-bg-black-90:focus {
  background-color: rgba(0, 0, 0, 0.9); }

.hover-bg-black-80:hover,
.hover-bg-black-80:focus {
  background-color: rgba(0, 0, 0, 0.8); }

.hover-bg-black-70:hover,
.hover-bg-black-70:focus {
  background-color: rgba(0, 0, 0, 0.7); }

.hover-bg-black-60:hover,
.hover-bg-black-60:focus {
  background-color: rgba(0, 0, 0, 0.6); }

.hover-bg-black-50:hover,
.hover-bg-black-50:focus {
  background-color: rgba(0, 0, 0, 0.5); }

.hover-bg-black-40:hover,
.hover-bg-black-40:focus {
  background-color: rgba(0, 0, 0, 0.4); }

.hover-bg-black-30:hover,
.hover-bg-black-30:focus {
  background-color: rgba(0, 0, 0, 0.3); }

.hover-bg-black-20:hover,
.hover-bg-black-20:focus {
  background-color: rgba(0, 0, 0, 0.2); }

.hover-bg-black-10:hover,
.hover-bg-black-10:focus {
  background-color: rgba(0, 0, 0, 0.1); }

.hover-bg-white-90:hover,
.hover-bg-white-90:focus {
  background-color: rgba(255, 255, 255, 0.9); }

.hover-bg-white-80:hover,
.hover-bg-white-80:focus {
  background-color: rgba(255, 255, 255, 0.8); }

.hover-bg-white-70:hover,
.hover-bg-white-70:focus {
  background-color: rgba(255, 255, 255, 0.7); }

.hover-bg-white-60:hover,
.hover-bg-white-60:focus {
  background-color: rgba(255, 255, 255, 0.6); }

.hover-bg-white-50:hover,
.hover-bg-white-50:focus {
  background-color: rgba(255, 255, 255, 0.5); }

.hover-bg-white-40:hover,
.hover-bg-white-40:focus {
  background-color: rgba(255, 255, 255, 0.4); }

.hover-bg-white-30:hover,
.hover-bg-white-30:focus {
  background-color: rgba(255, 255, 255, 0.3); }

.hover-bg-white-20:hover,
.hover-bg-white-20:focus {
  background-color: rgba(255, 255, 255, 0.2); }

.hover-bg-white-10:hover,
.hover-bg-white-10:focus {
  background-color: rgba(255, 255, 255, 0.1); }

.hover-dark-red:hover,
.hover-dark-red:focus {
  color: #e7040f; }

.hover-red:hover,
.hover-red:focus {
  color: #ff4136; }

.hover-light-red:hover,
.hover-light-red:focus {
  color: #ff725c; }

.hover-orange:hover,
.hover-orange:focus {
  color: #ff6300; }

.hover-gold:hover,
.hover-gold:focus {
  color: #ffb700; }

.hover-yellow:hover,
.hover-yellow:focus {
  color: #ffd700; }

.hover-light-yellow:hover,
.hover-light-yellow:focus {
  color: #fbf1a9; }

.hover-purple:hover,
.hover-purple:focus {
  color: #5e2ca5; }

.hover-light-purple:hover,
.hover-light-purple:focus {
  color: #a463f2; }

.hover-dark-pink:hover,
.hover-dark-pink:focus {
  color: #d5008f; }

.hover-hot-pink:hover,
.hover-hot-pink:focus {
  color: #ff41b4; }

.hover-pink:hover,
.hover-pink:focus {
  color: #ff80cc; }

.hover-light-pink:hover,
.hover-light-pink:focus {
  color: #ffa3d7; }

.hover-dark-green:hover,
.hover-dark-green:focus {
  color: #137752; }

.hover-green:hover,
.hover-green:focus {
  color: #19a974; }

.hover-light-green:hover,
.hover-light-green:focus {
  color: #9eebcf; }

.hover-navy:hover,
.hover-navy:focus {
  color: #001b44; }

.hover-dark-blue:hover,
.hover-dark-blue:focus {
  color: #00449e; }

.hover-blue:hover,
.hover-blue:focus {
  color: #357edd; }

.hover-light-blue:hover,
.hover-light-blue:focus {
  color: #96ccff; }

.hover-lightest-blue:hover,
.hover-lightest-blue:focus {
  color: #cdecff; }

.hover-washed-blue:hover,
.hover-washed-blue:focus {
  color: #f6fffe; }

.hover-washed-green:hover,
.hover-washed-green:focus {
  color: #e8fdf5; }

.hover-washed-yellow:hover,
.hover-washed-yellow:focus {
  color: #fffceb; }

.hover-washed-red:hover,
.hover-washed-red:focus {
  color: #ffdfdf; }

.hover-bg-dark-red:hover,
.hover-bg-dark-red:focus {
  background-color: #e7040f; }

.hover-bg-red:hover,
.hover-bg-red:focus {
  background-color: #ff4136; }

.hover-bg-light-red:hover,
.hover-bg-light-red:focus {
  background-color: #ff725c; }

.hover-bg-orange:hover,
.hover-bg-orange:focus {
  background-color: #ff6300; }

.hover-bg-gold:hover,
.hover-bg-gold:focus {
  background-color: #ffb700; }

.hover-bg-yellow:hover,
.hover-bg-yellow:focus {
  background-color: #ffd700; }

.hover-bg-light-yellow:hover,
.hover-bg-light-yellow:focus {
  background-color: #fbf1a9; }

.hover-bg-purple:hover,
.hover-bg-purple:focus {
  background-color: #5e2ca5; }

.hover-bg-light-purple:hover,
.hover-bg-light-purple:focus {
  background-color: #a463f2; }

.hover-bg-dark-pink:hover,
.hover-bg-dark-pink:focus {
  background-color: #d5008f; }

.hover-bg-hot-pink:hover,
.hover-bg-hot-pink:focus {
  background-color: #ff41b4; }

.hover-bg-pink:hover,
.hover-bg-pink:focus {
  background-color: #ff80cc; }

.hover-bg-light-pink:hover,
.hover-bg-light-pink:focus {
  background-color: #ffa3d7; }

.hover-bg-dark-green:hover,
.hover-bg-dark-green:focus {
  background-color: #137752; }

.hover-bg-green:hover,
.hover-bg-green:focus {
  background-color: #19a974; }

.hover-bg-light-green:hover,
.hover-bg-light-green:focus {
  background-color: #9eebcf; }

.hover-bg-navy:hover,
.hover-bg-navy:focus {
  background-color: #001b44; }

.hover-bg-dark-blue:hover,
.hover-bg-dark-blue:focus {
  background-color: #00449e; }

.hover-bg-blue:hover,
.hover-bg-blue:focus {
  background-color: #357edd; }

.hover-bg-light-blue:hover,
.hover-bg-light-blue:focus {
  background-color: #96ccff; }

.hover-bg-lightest-blue:hover,
.hover-bg-lightest-blue:focus {
  background-color: #cdecff; }

.hover-bg-washed-blue:hover,
.hover-bg-washed-blue:focus {
  background-color: #f6fffe; }

.hover-bg-washed-green:hover,
.hover-bg-washed-green:focus {
  background-color: #e8fdf5; }

.hover-bg-washed-yellow:hover,
.hover-bg-washed-yellow:focus {
  background-color: #fffceb; }

.hover-bg-washed-red:hover,
.hover-bg-washed-red:focus {
  background-color: #ffdfdf; }

.hover-bg-inherit:hover,
.hover-bg-inherit:focus {
  background-color: inherit; }

/*
   NEGATIVE MARGINS

   Base:
     n = negative

   Modifiers:
     a = all
     t = top
     r = right
     b = bottom
     l = left

     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.na1 {
  margin: -0.25rem; }

.na2 {
  margin: -0.5rem; }

.na3 {
  margin: -1rem; }

.na4 {
  margin: -2rem; }

.na5 {
  margin: -4rem; }

.na6 {
  margin: -8rem; }

.na7 {
  margin: -16rem; }

.nl1 {
  margin-left: -0.25rem; }

.nl2 {
  margin-left: -0.5rem; }

.nl3 {
  margin-left: -1rem; }

.nl4 {
  margin-left: -2rem; }

.nl5 {
  margin-left: -4rem; }

.nl6 {
  margin-left: -8rem; }

.nl7 {
  margin-left: -16rem; }

.nr1 {
  margin-right: -0.25rem; }

.nr2 {
  margin-right: -0.5rem; }

.nr3 {
  margin-right: -1rem; }

.nr4 {
  margin-right: -2rem; }

.nr5 {
  margin-right: -4rem; }

.nr6 {
  margin-right: -8rem; }

.nr7 {
  margin-right: -16rem; }

.nb1 {
  margin-bottom: -0.25rem; }

.nb2 {
  margin-bottom: -0.5rem; }

.nb3 {
  margin-bottom: -1rem; }

.nb4 {
  margin-bottom: -2rem; }

.nb5 {
  margin-bottom: -4rem; }

.nb6 {
  margin-bottom: -8rem; }

.nb7 {
  margin-bottom: -16rem; }

.nt1 {
  margin-top: -0.25rem; }

.nt2 {
  margin-top: -0.5rem; }

.nt3 {
  margin-top: -1rem; }

.nt4 {
  margin-top: -2rem; }

.nt5 {
  margin-top: -4rem; }

.nt6 {
  margin-top: -8rem; }

.nt7 {
  margin-top: -16rem; }

@media screen and (min-width: 30em) {
  .na1-ns {
    margin: -0.25rem; }
  .na2-ns {
    margin: -0.5rem; }
  .na3-ns {
    margin: -1rem; }
  .na4-ns {
    margin: -2rem; }
  .na5-ns {
    margin: -4rem; }
  .na6-ns {
    margin: -8rem; }
  .na7-ns {
    margin: -16rem; }
  .nl1-ns {
    margin-left: -0.25rem; }
  .nl2-ns {
    margin-left: -0.5rem; }
  .nl3-ns {
    margin-left: -1rem; }
  .nl4-ns {
    margin-left: -2rem; }
  .nl5-ns {
    margin-left: -4rem; }
  .nl6-ns {
    margin-left: -8rem; }
  .nl7-ns {
    margin-left: -16rem; }
  .nr1-ns {
    margin-right: -0.25rem; }
  .nr2-ns {
    margin-right: -0.5rem; }
  .nr3-ns {
    margin-right: -1rem; }
  .nr4-ns {
    margin-right: -2rem; }
  .nr5-ns {
    margin-right: -4rem; }
  .nr6-ns {
    margin-right: -8rem; }
  .nr7-ns {
    margin-right: -16rem; }
  .nb1-ns {
    margin-bottom: -0.25rem; }
  .nb2-ns {
    margin-bottom: -0.5rem; }
  .nb3-ns {
    margin-bottom: -1rem; }
  .nb4-ns {
    margin-bottom: -2rem; }
  .nb5-ns {
    margin-bottom: -4rem; }
  .nb6-ns {
    margin-bottom: -8rem; }
  .nb7-ns {
    margin-bottom: -16rem; }
  .nt1-ns {
    margin-top: -0.25rem; }
  .nt2-ns {
    margin-top: -0.5rem; }
  .nt3-ns {
    margin-top: -1rem; }
  .nt4-ns {
    margin-top: -2rem; }
  .nt5-ns {
    margin-top: -4rem; }
  .nt6-ns {
    margin-top: -8rem; }
  .nt7-ns {
    margin-top: -16rem; } }

@media (min-width: 48em) {
  .na1-m {
    margin: -0.25rem; }
  .na2-m {
    margin: -0.5rem; }
  .na3-m {
    margin: -1rem; }
  .na4-m {
    margin: -2rem; }
  .na5-m {
    margin: -4rem; }
  .na6-m {
    margin: -8rem; }
  .na7-m {
    margin: -16rem; }
  .nl1-m {
    margin-left: -0.25rem; }
  .nl2-m {
    margin-left: -0.5rem; }
  .nl3-m {
    margin-left: -1rem; }
  .nl4-m {
    margin-left: -2rem; }
  .nl5-m {
    margin-left: -4rem; }
  .nl6-m {
    margin-left: -8rem; }
  .nl7-m {
    margin-left: -16rem; }
  .nr1-m {
    margin-right: -0.25rem; }
  .nr2-m {
    margin-right: -0.5rem; }
  .nr3-m {
    margin-right: -1rem; }
  .nr4-m {
    margin-right: -2rem; }
  .nr5-m {
    margin-right: -4rem; }
  .nr6-m {
    margin-right: -8rem; }
  .nr7-m {
    margin-right: -16rem; }
  .nb1-m {
    margin-bottom: -0.25rem; }
  .nb2-m {
    margin-bottom: -0.5rem; }
  .nb3-m {
    margin-bottom: -1rem; }
  .nb4-m {
    margin-bottom: -2rem; }
  .nb5-m {
    margin-bottom: -4rem; }
  .nb6-m {
    margin-bottom: -8rem; }
  .nb7-m {
    margin-bottom: -16rem; }
  .nt1-m {
    margin-top: -0.25rem; }
  .nt2-m {
    margin-top: -0.5rem; }
  .nt3-m {
    margin-top: -1rem; }
  .nt4-m {
    margin-top: -2rem; }
  .nt5-m {
    margin-top: -4rem; }
  .nt6-m {
    margin-top: -8rem; }
  .nt7-m {
    margin-top: -16rem; } }

@media (min-width: 70em) {
  .na1-l {
    margin: -0.25rem; }
  .na2-l {
    margin: -0.5rem; }
  .na3-l {
    margin: -1rem; }
  .na4-l {
    margin: -2rem; }
  .na5-l {
    margin: -4rem; }
  .na6-l {
    margin: -8rem; }
  .na7-l {
    margin: -16rem; }
  .nl1-l {
    margin-left: -0.25rem; }
  .nl2-l {
    margin-left: -0.5rem; }
  .nl3-l {
    margin-left: -1rem; }
  .nl4-l {
    margin-left: -2rem; }
  .nl5-l {
    margin-left: -4rem; }
  .nl6-l {
    margin-left: -8rem; }
  .nl7-l {
    margin-left: -16rem; }
  .nr1-l {
    margin-right: -0.25rem; }
  .nr2-l {
    margin-right: -0.5rem; }
  .nr3-l {
    margin-right: -1rem; }
  .nr4-l {
    margin-right: -2rem; }
  .nr5-l {
    margin-right: -4rem; }
  .nr6-l {
    margin-right: -8rem; }
  .nr7-l {
    margin-right: -16rem; }
  .nb1-l {
    margin-bottom: -0.25rem; }
  .nb2-l {
    margin-bottom: -0.5rem; }
  .nb3-l {
    margin-bottom: -1rem; }
  .nb4-l {
    margin-bottom: -2rem; }
  .nb5-l {
    margin-bottom: -4rem; }
  .nb6-l {
    margin-bottom: -8rem; }
  .nb7-l {
    margin-bottom: -16rem; }
  .nt1-l {
    margin-top: -0.25rem; }
  .nt2-l {
    margin-top: -0.5rem; }
  .nt3-l {
    margin-top: -1rem; }
  .nt4-l {
    margin-top: -2rem; }
  .nt5-l {
    margin-top: -4rem; }
  .nt6-l {
    margin-top: -8rem; }
  .nt7-l {
    margin-top: -16rem; } }

/*

  TABLES
  Docs: http://tachyons.io/docs/elements/tables/

*/
.collapse {
  border-collapse: collapse;
  border-spacing: 0; }

.striped--light-silver:nth-child(odd) {
  background-color: #aaa; }

.striped--moon-gray:nth-child(odd) {
  background-color: #ccc; }

.striped--light-gray:nth-child(odd) {
  background-color: #eee; }

.striped--near-white:nth-child(odd) {
  background-color: #f4f4f4; }

.stripe-light:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.1); }

.stripe-dark:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1); }

/*

   TEXT DECORATION
   Docs: http://tachyons.io/docs/typography/text-decoration/


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.strike {
  text-decoration: line-through; }

.underline {
  text-decoration: underline; }

.no-underline {
  text-decoration: none; }

@media screen and (min-width: 30em) {
  .strike-ns {
    text-decoration: line-through; }
  .underline-ns {
    text-decoration: underline; }
  .no-underline-ns {
    text-decoration: none; } }

@media (min-width: 48em) {
  .strike-m {
    text-decoration: line-through; }
  .underline-m {
    text-decoration: underline; }
  .no-underline-m {
    text-decoration: none; } }

@media (min-width: 70em) {
  .strike-l {
    text-decoration: line-through; }
  .underline-l {
    text-decoration: underline; }
  .no-underline-l {
    text-decoration: none; } }

/*

  TEXT ALIGN
  Docs: http://tachyons.io/docs/typography/text-align/

  Base
    t = text-align

  Modifiers
    l = left
    r = right
    c = center
    j = justify

  Media Query Extensions:
    -ns = not-small
    -m  = medium
    -l  = large

*/
.tl {
  text-align: left; }

.tr {
  text-align: right; }

.tc {
  text-align: center; }

.tj {
  text-align: justify; }

@media screen and (min-width: 30em) {
  .tl-ns {
    text-align: left; }
  .tr-ns {
    text-align: right; }
  .tc-ns {
    text-align: center; }
  .tj-ns {
    text-align: justify; } }

@media (min-width: 48em) {
  .tl-m {
    text-align: left; }
  .tr-m {
    text-align: right; }
  .tc-m {
    text-align: center; }
  .tj-m {
    text-align: justify; } }

@media (min-width: 70em) {
  .tl-l {
    text-align: left; }
  .tr-l {
    text-align: right; }
  .tc-l {
    text-align: center; }
  .tj-l {
    text-align: justify; } }

/*

   TEXT TRANSFORM
   Docs: http://tachyons.io/docs/typography/text-transform/

   Base:
     tt = text-transform

   Modifiers
     c = capitalize
     l = lowercase
     u = uppercase
     n = none

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.ttc {
  text-transform: capitalize; }

.ttl {
  text-transform: lowercase; }

.ttu {
  text-transform: uppercase; }

.ttn {
  text-transform: none; }

@media screen and (min-width: 30em) {
  .ttc-ns {
    text-transform: capitalize; }
  .ttl-ns {
    text-transform: lowercase; }
  .ttu-ns {
    text-transform: uppercase; }
  .ttn-ns {
    text-transform: none; } }

@media (min-width: 48em) {
  .ttc-m {
    text-transform: capitalize; }
  .ttl-m {
    text-transform: lowercase; }
  .ttu-m {
    text-transform: uppercase; }
  .ttn-m {
    text-transform: none; } }

@media (min-width: 70em) {
  .ttc-l {
    text-transform: capitalize; }
  .ttl-l {
    text-transform: lowercase; }
  .ttu-l {
    text-transform: uppercase; }
  .ttn-l {
    text-transform: none; } }

/*

   TYPE SCALE
   Docs: http://tachyons.io/docs/typography/scale/

   Base:
    f = font-size

   Modifiers
     1 = 1st step in size scale
     2 = 2nd step in size scale
     3 = 3rd step in size scale
     4 = 4th step in size scale
     5 = 5th step in size scale
     6 = 6th step in size scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large
*/
/*
 * For Hero/Marketing Titles
 *
 * These generally are too large for mobile
 * so be careful using them on smaller screens.
 * */
.f-6,
.f-headline {
  font-size: 6rem; }

.f-5,
.f-subheadline {
  font-size: 5rem; }

/* Type Scale */
.f1 {
  font-size: 3rem; }

.f2 {
  font-size: 2.25rem; }

.f3 {
  font-size: 1.5rem; }

.f4 {
  font-size: 1.25rem; }

.f5 {
  font-size: 1rem; }

.f6 {
  font-size: 0.875rem; }

.f7 {
  font-size: 0.75rem; }

@media screen and (min-width: 30em) {
  .f-6-ns,
  .f-headline-ns {
    font-size: 6rem; }
  .f-5-ns,
  .f-subheadline-ns {
    font-size: 5rem; }
  .f1-ns {
    font-size: 3rem; }
  .f2-ns {
    font-size: 2.25rem; }
  .f3-ns {
    font-size: 1.5rem; }
  .f4-ns {
    font-size: 1.25rem; }
  .f5-ns {
    font-size: 1rem; }
  .f6-ns {
    font-size: 0.875rem; }
  .f7-ns {
    font-size: 0.75rem; } }

@media (min-width: 48em) {
  .f-6-m,
  .f-headline-m {
    font-size: 6rem; }
  .f-5-m,
  .f-subheadline-m {
    font-size: 5rem; }
  .f1-m {
    font-size: 3rem; }
  .f2-m {
    font-size: 2.25rem; }
  .f3-m {
    font-size: 1.5rem; }
  .f4-m {
    font-size: 1.25rem; }
  .f5-m {
    font-size: 1rem; }
  .f6-m {
    font-size: 0.875rem; }
  .f7-m {
    font-size: 0.75rem; } }

@media (min-width: 70em) {
  .f-6-l,
  .f-headline-l {
    font-size: 6rem; }
  .f-5-l,
  .f-subheadline-l {
    font-size: 5rem; }
  .f1-l {
    font-size: 3rem; }
  .f2-l {
    font-size: 2.25rem; }
  .f3-l {
    font-size: 1.5rem; }
  .f4-l {
    font-size: 1.25rem; }
  .f5-l {
    font-size: 1rem; }
  .f6-l {
    font-size: 0.875rem; }
  .f7-l {
    font-size: 0.75rem; } }

/*

   TYPOGRAPHY
   http://tachyons.io/docs/typography/measure/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
/* Measure is limited to ~66 characters */
.measure {
  max-width: 30em; }

/* Measure is limited to ~80 characters */
.measure-wide {
  max-width: 34em; }

/* Measure is limited to ~45 characters */
.measure-narrow {
  max-width: 20em; }

/* Book paragraph style - paragraphs are indented with no vertical spacing. */
.indent {
  text-indent: 1em;
  margin-top: 0;
  margin-bottom: 0; }

.small-caps {
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps; }

/* Combine this class with a width to truncate text (or just leave as is to truncate at width of containing element. */
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

@media screen and (min-width: 30em) {
  .measure-ns {
    max-width: 30em; }
  .measure-wide-ns {
    max-width: 34em; }
  .measure-narrow-ns {
    max-width: 20em; }
  .indent-ns {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0; }
  .small-caps-ns {
    -webkit-font-feature-settings: "c2sc";
            font-feature-settings: "c2sc";
    font-variant: small-caps; }
  .truncate-ns {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

@media (min-width: 48em) {
  .measure-m {
    max-width: 30em; }
  .measure-wide-m {
    max-width: 34em; }
  .measure-narrow-m {
    max-width: 20em; }
  .indent-m {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0; }
  .small-caps-m {
    -webkit-font-feature-settings: "c2sc";
            font-feature-settings: "c2sc";
    font-variant: small-caps; }
  .truncate-m {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

@media (min-width: 70em) {
  .measure-l {
    max-width: 30em; }
  .measure-wide-l {
    max-width: 34em; }
  .measure-narrow-l {
    max-width: 20em; }
  .indent-l {
    text-indent: 1em;
    margin-top: 0;
    margin-bottom: 0; }
  .small-caps-l {
    -webkit-font-feature-settings: "c2sc";
            font-feature-settings: "c2sc";
    font-variant: small-caps; }
  .truncate-l {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; } }

/*

   UTILITIES

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
/* Equivalent to .overflow-y-scroll */
.overflow-container {
  overflow-y: scroll; }

.center {
  margin-right: auto;
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.ml-auto {
  margin-left: auto; }

@media screen and (min-width: 30em) {
  .center-ns {
    margin-right: auto;
    margin-left: auto; }
  .mr-auto-ns {
    margin-right: auto; }
  .ml-auto-ns {
    margin-left: auto; } }

@media (min-width: 48em) {
  .center-m {
    margin-right: auto;
    margin-left: auto; }
  .mr-auto-m {
    margin-right: auto; }
  .ml-auto-m {
    margin-left: auto; } }

@media (min-width: 70em) {
  .center-l {
    margin-right: auto;
    margin-left: auto; }
  .mr-auto-l {
    margin-right: auto; }
  .ml-auto-l {
    margin-left: auto; } }

/*

   VISIBILITY

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
/*
    Text that is hidden but accessible
    Ref: http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
*/
.clip {
  position: fixed !important;
  _position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px); }

@media screen and (min-width: 30em) {
  .clip-ns {
    position: fixed !important;
    _position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px); } }

@media (min-width: 48em) {
  .clip-m {
    position: fixed !important;
    _position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px); } }

@media (min-width: 70em) {
  .clip-l {
    position: fixed !important;
    _position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px); } }

/*

   WHITE SPACE

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.ws-normal {
  white-space: normal; }

.nowrap {
  white-space: nowrap; }

.pre {
  white-space: pre; }

@media screen and (min-width: 30em) {
  .ws-normal-ns {
    white-space: normal; }
  .nowrap-ns {
    white-space: nowrap; }
  .pre-ns {
    white-space: pre; } }

@media (min-width: 48em) {
  .ws-normal-m {
    white-space: normal; }
  .nowrap-m {
    white-space: nowrap; }
  .pre-m {
    white-space: pre; } }

@media (min-width: 70em) {
  .ws-normal-l {
    white-space: normal; }
  .nowrap-l {
    white-space: nowrap; }
  .pre-l {
    white-space: pre; } }

/*

   VERTICAL ALIGN

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.v-base {
  vertical-align: baseline; }

.v-mid {
  vertical-align: middle; }

.v-top {
  vertical-align: top; }

.v-btm {
  vertical-align: bottom; }

@media screen and (min-width: 30em) {
  .v-base-ns {
    vertical-align: baseline; }
  .v-mid-ns {
    vertical-align: middle; }
  .v-top-ns {
    vertical-align: top; }
  .v-btm-ns {
    vertical-align: bottom; } }

@media (min-width: 48em) {
  .v-base-m {
    vertical-align: baseline; }
  .v-mid-m {
    vertical-align: middle; }
  .v-top-m {
    vertical-align: top; }
  .v-btm-m {
    vertical-align: bottom; } }

@media (min-width: 70em) {
  .v-base-l {
    vertical-align: baseline; }
  .v-mid-l {
    vertical-align: middle; }
  .v-top-l {
    vertical-align: top; }
  .v-btm-l {
    vertical-align: bottom; } }

/*

  HOVER EFFECTS
  Docs: http://tachyons.io/docs/themes/hovers/

    - Dim
    - Glow
    - Hide Child
    - Underline text
    - Grow
    - Pointer
    - Shadow

*/
/*

  Dim element on hover by adding the dim class.

*/
.dim {
  opacity: 1;
  -webkit-transition: opacity .15s ease-in;
  transition: opacity .15s ease-in; }

.dim:hover,
.dim:focus {
  opacity: .5;
  -webkit-transition: opacity .15s ease-in;
  transition: opacity .15s ease-in; }

.dim:active {
  opacity: .8;
  -webkit-transition: opacity .15s ease-out;
  transition: opacity .15s ease-out; }

/*

  Animate opacity to 100% on hover by adding the glow class.

*/
.glow {
  -webkit-transition: opacity .15s ease-in;
  transition: opacity .15s ease-in; }

.glow:hover,
.glow:focus {
  opacity: 1;
  -webkit-transition: opacity .15s ease-in;
  transition: opacity .15s ease-in; }

/*

  Hide child & reveal on hover:

  Put the hide-child class on a parent element and any nested element with the
  child class will be hidden and displayed on hover or focus.

  <div class="hide-child">
    <div class="child"> Hidden until hover or focus </div>
    <div class="child"> Hidden until hover or focus </div>
    <div class="child"> Hidden until hover or focus </div>
    <div class="child"> Hidden until hover or focus </div>
  </div>
*/
.hide-child .child {
  opacity: 0;
  -webkit-transition: opacity .15s ease-in;
  transition: opacity .15s ease-in; }

.hide-child:hover .child,
.hide-child:focus .child,
.hide-child:active .child {
  opacity: 1;
  -webkit-transition: opacity .15s ease-in;
  transition: opacity .15s ease-in; }

.underline-hover:hover,
.underline-hover:focus {
  text-decoration: underline; }

/* Can combine this with overflow-hidden to make background images grow on hover
 * even if you are using background-size: cover */
.grow {
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out; }

.grow:hover,
.grow:focus {
  -webkit-transform: scale(1.05);
          transform: scale(1.05); }

.grow:active {
  -webkit-transform: scale(0.9);
          transform: scale(0.9); }

.grow-large {
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-transition: -webkit-transform .25s ease-in-out;
  transition: -webkit-transform .25s ease-in-out;
  transition: transform .25s ease-in-out;
  transition: transform .25s ease-in-out, -webkit-transform .25s ease-in-out; }

.grow-large:hover,
.grow-large:focus {
  -webkit-transform: scale(1.2);
          transform: scale(1.2); }

.grow-large:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95); }

/* Add pointer on hover */
.pointer:hover {
  cursor: pointer; }

/*
   Add shadow on hover.

   Performant box-shadow animation pattern from
   http://tobiasahlin.com/blog/how-to-animate-box-shadow/
*/
.shadow-hover {
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1); }

.shadow-hover::after {
  content: '';
  box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.2);
  border-radius: inherit;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1); }

.shadow-hover:hover::after,
.shadow-hover:focus::after {
  opacity: 1; }

/* Combine with classes in skins and skins-pseudo for
 * many different transition possibilities. */
.bg-animate,
.bg-animate:hover,
.bg-animate:focus {
  -webkit-transition: background-color .15s ease-in-out;
  transition: background-color .15s ease-in-out; }

/*

  Z-INDEX

  Base
    z = z-index

  Modifiers
    -0 = literal value 0
    -1 = literal value 1
    -2 = literal value 2
    -3 = literal value 3
    -4 = literal value 4
    -5 = literal value 5
    -999 = literal value 999
    -9999 = literal value 9999

    -max = largest accepted z-index value as integer

    -inherit = string value inherit
    -initial = string value initial
    -unset = string value unset

  MDN: https://developer.mozilla.org/en/docs/Web/CSS/z-index
  Spec: http://www.w3.org/TR/CSS2/zindex.html
  Articles:
    https://philipwalton.com/articles/what-no-one-told-you-about-z-index/

  Tips on extending:
  There might be a time worth using negative z-index values.
  Or if you are using tachyons with another project, you might need to
  adjust these values to suit your needs.

*/
.z-0 {
  z-index: 0; }

.z-1 {
  z-index: 1; }

.z-2 {
  z-index: 2; }

.z-3 {
  z-index: 3; }

.z-4 {
  z-index: 4; }

.z-5 {
  z-index: 5; }

.z-999 {
  z-index: 999; }

.z-9999 {
  z-index: 9999; }

.z-max {
  z-index: 2147483647; }

.z-inherit {
  z-index: inherit; }

.z-initial {
  z-index: auto;
  z-index: initial; }

.z-unset {
  z-index: unset; }

/*

    NESTED
    Tachyons module for styling nested elements
    that are generated by a cms.

*/
.nested-copy-line-height p,
.nested-copy-line-height ul,
.nested-copy-line-height ol {
  line-height: 1.5; }

.nested-headline-line-height h1,
.nested-headline-line-height h2,
.nested-headline-line-height h3,
.nested-headline-line-height h4,
.nested-headline-line-height h5,
.nested-headline-line-height h6 {
  line-height: 1.25; }

.nested-list-reset ul,
.nested-list-reset ol {
  padding-left: 0;
  margin-left: 0;
  list-style-type: none; }

.nested-copy-indent p + p {
  text-indent: 0.1em;
  margin-top: 0;
  margin-bottom: 0; }

.nested-copy-seperator p + p {
  margin-top: 1.5em; }

.nested-img img {
  width: 100%;
  max-width: 100%;
  display: block; }

.nested-links a {
  color: #357edd;
  -webkit-transition: color .15s ease-in;
  transition: color .15s ease-in; }

.nested-links a:hover,
.nested-links a:focus {
  color: #96ccff;
  -webkit-transition: color .15s ease-in;
  transition: color .15s ease-in; }

/*

  STYLES

  Add custom styles here.

*/
.fw400 {
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 400;
  font-style: normal; }

.fw200 {
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 200;
  font-style: normal; }

.fw800 {
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 800;
  font-style: normal; }

.nav-link {
  font-family: mr-eaves-modern, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  font-style: normal; }

.nav-link-small {
  font-family: mr-eaves-modern, sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  font-style: normal; }

body {
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 400;
  margin: 0; }

a.link {
  font-weight: 400;
  font-family: aktiv-grotesk, sans-serif; }

.parallax {
  background-attachment: fixed; }

.bw-cta {
  border-width: 2px; }

.black-grad {
  background: -webkit-linear-gradient(left, white 0%, black 100%);
  background: linear-gradient(to right, white 0%, black 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.cart-img {
  height: 100px;
  width: auto; }

.shadow-card {
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.025); }

.pointer {
  cursor: pointer; }

.rotate {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite; }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #000;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite; }

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

.form-cl {
  border-width: 1px;
  background-color: white;
  font-size: 14px;
  line-height: 21px;
  padding: 4px 8px; }

.cart-table {
  border-collapse: collapse; }
  .cart-table th {
    padding-bottom: 8px; }

.order-table {
  border-collapse: collapse; }
  .order-table th, .order-table td {
    padding: 8px 16px; }

.sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s; }

.shadow-6 {
  box-shadow: 0px 6px 5px 4px #dddddd; }

/* 4px */
/* 8px */
/* 16px */
/* 24px */
/* 32px */
/* 48px */
/* 64px */
/* 96px */
/* 128px */
/*
   SPACING
   A 10 step scale ranging from 0 to 8rem.
   Base:
     p = padding
     m = margin
   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left
     0 = none
     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale
     8 = 8th step in spacing scale
     9 - 9th step in spacing scale
   Media Query Extensions:
     -m  = medium
     -l  = large
*/
.break-word {
  word-wrap: break-word;
  overflow-wrap: break-word; }

.pa0 {
  padding: 0; }

.pl0 {
  padding-left: 0; }

.pr0 {
  padding-right: 0; }

.pb0 {
  padding-bottom: 0; }

.pt0 {
  padding-top: 0; }

.pv0 {
  padding-top: 0;
  padding-bottom: 0; }

.ph0 {
  padding-left: 0;
  padding-right: 0; }

.ma0 {
  margin: 0; }

.ml0 {
  margin-left: 0; }

.mr0 {
  margin-right: 0; }

.mb0 {
  margin-bottom: 0; }

.mt0 {
  margin-top: 0; }

.mv0 {
  margin-top: 0;
  margin-bottom: 0; }

.mh0 {
  margin-left: 0;
  margin-right: 0; }

.pa1 {
  padding: 0.25rem; }

.pl1 {
  padding-left: 0.25rem; }

.pr1 {
  padding-right: 0.25rem; }

.pb1 {
  padding-bottom: 0.25rem; }

.pt1 {
  padding-top: 0.25rem; }

.pv1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.ph1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.ma1 {
  margin: 0.25rem; }

.ml1 {
  margin-left: 0.25rem; }

.mr1 {
  margin-right: 0.25rem; }

.mb1 {
  margin-bottom: 0.25rem; }

.mt1 {
  margin-top: 0.25rem; }

.mv1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.mh1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.pa2 {
  padding: 0.5rem; }

.pl2 {
  padding-left: 0.5rem; }

.pr2 {
  padding-right: 0.5rem; }

.pb2 {
  padding-bottom: 0.5rem; }

.pt2 {
  padding-top: 0.5rem; }

.pv2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.ph2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.ma2 {
  margin: 0.5rem; }

.ml2 {
  margin-left: 0.5rem; }

.mr2 {
  margin-right: 0.5rem; }

.mb2 {
  margin-bottom: 0.5rem; }

.mt2 {
  margin-top: 0.5rem; }

.mv2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.mh2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.pa3 {
  padding: 1rem; }

.pl3 {
  padding-left: 1rem; }

.pr3 {
  padding-right: 1rem; }

.pb3 {
  padding-bottom: 1rem; }

.pt3 {
  padding-top: 1rem; }

.pv3 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.ph3 {
  padding-left: 1rem;
  padding-right: 1rem; }

.ma3 {
  margin: 1rem; }

.ml3 {
  margin-left: 1rem; }

.mr3 {
  margin-right: 1rem; }

.mb3 {
  margin-bottom: 1rem; }

.mt3 {
  margin-top: 1rem; }

.mv3 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.mh3 {
  margin-left: 1rem;
  margin-right: 1rem; }

.pa4 {
  padding: 1.5rem; }

.pl4 {
  padding-left: 1.5rem; }

.pr4 {
  padding-right: 1.5rem; }

.pb4 {
  padding-bottom: 1.5rem; }

.pt4 {
  padding-top: 1.5rem; }

.pv4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.ph4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.ma4 {
  margin: 1.5rem; }

.ml4 {
  margin-left: 1.5rem; }

.mr4 {
  margin-right: 1.5rem; }

.mb4 {
  margin-bottom: 1.5rem; }

.mt4 {
  margin-top: 1.5rem; }

.mv4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.mh4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.pa5 {
  padding: 2rem; }

.pl5 {
  padding-left: 2rem; }

.pr5 {
  padding-right: 2rem; }

.pb5 {
  padding-bottom: 2rem; }

.pt5 {
  padding-top: 2rem; }

.pv5 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.ph5 {
  padding-left: 2rem;
  padding-right: 2rem; }

.ma5 {
  margin: 2rem; }

.ml5 {
  margin-left: 2rem; }

.mr5 {
  margin-right: 2rem; }

.mb5 {
  margin-bottom: 2rem; }

.mt5 {
  margin-top: 2rem; }

.mv5 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.mh5 {
  margin-left: 2rem;
  margin-right: 2rem; }

.pa6 {
  padding: 3rem; }

.pl6 {
  padding-left: 3rem; }

.pr6 {
  padding-right: 3rem; }

.pb6 {
  padding-bottom: 3rem; }

.pt6 {
  padding-top: 3rem; }

.pv6 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.ph6 {
  padding-left: 3rem;
  padding-right: 3rem; }

.ma6 {
  margin: 3rem; }

.ml6 {
  margin-left: 3rem; }

.mr6 {
  margin-right: 3rem; }

.mb6 {
  margin-bottom: 3rem; }

.mt6 {
  margin-top: 3rem; }

.mv6 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.mh6 {
  margin-left: 3rem;
  margin-right: 3rem; }

.pa7 {
  padding: 4rem; }

.pl7 {
  padding-left: 4rem; }

.pr7 {
  padding-right: 4rem; }

.pb7 {
  padding-bottom: 4rem; }

.pt7 {
  padding-top: 4rem; }

.pv7 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.ph7 {
  padding-left: 4rem;
  padding-right: 4rem; }

.ma7 {
  margin: 4rem; }

.ml7 {
  margin-left: 4rem; }

.mr7 {
  margin-right: 4rem; }

.mb7 {
  margin-bottom: 4rem; }

.mt7 {
  margin-top: 4rem; }

.mv7 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.mh7 {
  margin-left: 4rem;
  margin-right: 4rem; }

.pa8 {
  padding: 6rem; }

.pl8 {
  padding-left: 6rem; }

.pr8 {
  padding-right: 6rem; }

.pb8 {
  padding-bottom: 6rem; }

.pt8 {
  padding-top: 6rem; }

.pv8 {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.ph8 {
  padding-left: 6rem;
  padding-right: 6rem; }

.ma8 {
  margin: 6rem; }

.ml8 {
  margin-left: 6rem; }

.mr8 {
  margin-right: 6rem; }

.mb8 {
  margin-bottom: 6rem; }

.mt8 {
  margin-top: 6rem; }

.mv8 {
  margin-top: 6rem;
  margin-bottom: 6rem; }

.mh8 {
  margin-left: 6rem;
  margin-right: 6rem; }

.pa9 {
  padding: 8rem; }

.pl9 {
  padding-left: 8rem; }

.pr9 {
  padding-right: 8rem; }

.pb9 {
  padding-bottom: 8rem; }

.pt9 {
  padding-top: 8rem; }

.pv9 {
  padding-top: 8rem;
  padding-bottom: 8rem; }

.ph9 {
  padding-left: 8rem;
  padding-right: 8rem; }

.ma9 {
  margin: 8rem; }

.ml9 {
  margin-left: 8rem; }

.mr9 {
  margin-right: 8rem; }

.mb9 {
  margin-bottom: 8rem; }

.mt9 {
  margin-top: 8rem; }

.mv9 {
  margin-top: 8rem;
  margin-bottom: 8rem; }

.mh9 {
  margin-left: 8rem;
  margin-right: 8rem; }

@media (min-width: 29em) {
  .pa0-s {
    padding: 0; }
  .pl0-s {
    padding-left: 0; }
  .pr0-s {
    padding-right: 0; }
  .pb0-s {
    padding-bottom: 0; }
  .pt0-s {
    padding-top: 0; }
  .pv0-s {
    padding-top: 0;
    padding-bottom: 0; }
  .ph0-s {
    padding-left: 0;
    padding-right: 0; }
  .ma0-s {
    margin: 0; }
  .ml0-s {
    margin-left: 0; }
  .mr0-s {
    margin-right: 0; }
  .mb0-s {
    margin-bottom: 0; }
  .mt0-s {
    margin-top: 0; }
  .mv0-s {
    margin-top: 0;
    margin-bottom: 0; }
  .mh0-s {
    margin-left: 0;
    margin-right: 0; }
  .pa1-s {
    padding: 0.25rem; }
  .pl1-s {
    padding-left: 0.25rem; }
  .pr1-s {
    padding-right: 0.25rem; }
  .pb1-s {
    padding-bottom: 0.25rem; }
  .pt1-s {
    padding-top: 0.25rem; }
  .pv1-s {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .ph1-s {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .ma1-s {
    margin: 0.25rem; }
  .ml1-s {
    margin-left: 0.25rem; }
  .mr1-s {
    margin-right: 0.25rem; }
  .mb1-s {
    margin-bottom: 0.25rem; }
  .mt1-s {
    margin-top: 0.25rem; }
  .mv1-s {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .mh1-s {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .pa2-s {
    padding: 0.5rem; }
  .pl2-s {
    padding-left: 0.5rem; }
  .pr2-s {
    padding-right: 0.5rem; }
  .pb2-s {
    padding-bottom: 0.5rem; }
  .pt2-s {
    padding-top: 0.5rem; }
  .pv2-s {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .ph2-s {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .ma2-s {
    margin: 0.5rem; }
  .ml2-s {
    margin-left: 0.5rem; }
  .mr2-s {
    margin-right: 0.5rem; }
  .mb2-s {
    margin-bottom: 0.5rem; }
  .mt2-s {
    margin-top: 0.5rem; }
  .mv2-s {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .mh2-s {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .pa3-s {
    padding: 1rem; }
  .pl3-s {
    padding-left: 1rem; }
  .pr3-s {
    padding-right: 1rem; }
  .pb3-s {
    padding-bottom: 1rem; }
  .pt3-s {
    padding-top: 1rem; }
  .pv3-s {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .ph3-s {
    padding-left: 1rem;
    padding-right: 1rem; }
  .ma3-s {
    margin: 1rem; }
  .ml3-s {
    margin-left: 1rem; }
  .mr3-s {
    margin-right: 1rem; }
  .mb3-s {
    margin-bottom: 1rem; }
  .mt3-s {
    margin-top: 1rem; }
  .mv3-s {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .mh3-s {
    margin-left: 1rem;
    margin-right: 1rem; }
  .pa4-s {
    padding: 1.5rem; }
  .pl4-s {
    padding-left: 1.5rem; }
  .pr4-s {
    padding-right: 1.5rem; }
  .pb4-s {
    padding-bottom: 1.5rem; }
  .pt4-s {
    padding-top: 1.5rem; }
  .pv4-s {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .ph4-s {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .ma4-s {
    margin: 1.5rem; }
  .ml4-s {
    margin-left: 1.5rem; }
  .mr4-s {
    margin-right: 1.5rem; }
  .mb4-s {
    margin-bottom: 1.5rem; }
  .mt4-s {
    margin-top: 1.5rem; }
  .mv4-s {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .mh4-s {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .pa5-s {
    padding: 2rem; }
  .pl5-s {
    padding-left: 2rem; }
  .pr5-s {
    padding-right: 2rem; }
  .pb5-s {
    padding-bottom: 2rem; }
  .pt5-s {
    padding-top: 2rem; }
  .pv5-s {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .ph5-s {
    padding-left: 2rem;
    padding-right: 2rem; }
  .ma5-s {
    margin: 2rem; }
  .ml5-s {
    margin-left: 2rem; }
  .mr5-s {
    margin-right: 2rem; }
  .mb5-s {
    margin-bottom: 2rem; }
  .mt5-s {
    margin-top: 2rem; }
  .mv5-s {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .mh5-s {
    margin-left: 2rem;
    margin-right: 2rem; }
  .pa6-s {
    padding: 3rem; }
  .pl6-s {
    padding-left: 3rem; }
  .pr6-s {
    padding-right: 3rem; }
  .pb6-s {
    padding-bottom: 3rem; }
  .pt6-s {
    padding-top: 3rem; }
  .pv6-s {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .ph6-s {
    padding-left: 3rem;
    padding-right: 3rem; }
  .ma6-s {
    margin: 3rem; }
  .ml6-s {
    margin-left: 3rem; }
  .mr6-s {
    margin-right: 3rem; }
  .mb6-s {
    margin-bottom: 3rem; }
  .mt6-s {
    margin-top: 3rem; }
  .mv6-s {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .mh6-s {
    margin-left: 3rem;
    margin-right: 3rem; }
  .pa7-s {
    padding: 4rem; }
  .pl7-s {
    padding-left: 4rem; }
  .pr7-s {
    padding-right: 4rem; }
  .pb7-s {
    padding-bottom: 4rem; }
  .pt7-s {
    padding-top: 4rem; }
  .pv7-s {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .ph7-s {
    padding-left: 4rem;
    padding-right: 4rem; }
  .ma7-s {
    margin: 4rem; }
  .ml7-s {
    margin-left: 4rem; }
  .mr7-s {
    margin-right: 4rem; }
  .mb7-s {
    margin-bottom: 4rem; }
  .mt7-s {
    margin-top: 4rem; }
  .mv7-s {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .mh7-s {
    margin-left: 4rem;
    margin-right: 4rem; }
  .pa8-s {
    padding: 6rem; }
  .pl8-s {
    padding-left: 6rem; }
  .pr8-s {
    padding-right: 6rem; }
  .pb8-s {
    padding-bottom: 6rem; }
  .pt8-s {
    padding-top: 6rem; }
  .pv8-s {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .ph8-s {
    padding-left: 6rem;
    padding-right: 6rem; }
  .ma8-s {
    margin: 6rem; }
  .ml8-s {
    margin-left: 6rem; }
  .mr8-s {
    margin-right: 6rem; }
  .mb8-s {
    margin-bottom: 6rem; }
  .mt8-s {
    margin-top: 6rem; }
  .mv8-s {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .mh8-s {
    margin-left: 6rem;
    margin-right: 6rem; }
  .pa9-s {
    padding: 8rem; }
  .pl9-s {
    padding-left: 8rem; }
  .pr9-s {
    padding-right: 8rem; }
  .pb9-s {
    padding-bottom: 8rem; }
  .pt9-s {
    padding-top: 8rem; }
  .pv9-s {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .ph9-s {
    padding-left: 8rem;
    padding-right: 8rem; }
  .ma9-s {
    margin: 8rem; }
  .ml9-s {
    margin-left: 8rem; }
  .mr9-s {
    margin-right: 8rem; }
  .mb9-s {
    margin-bottom: 8rem; }
  .mt9-s {
    margin-top: 8rem; }
  .mv9-s {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .mh9-s {
    margin-left: 8rem;
    margin-right: 8rem; } }

@media (min-width: 48em) {
  .pa0-m {
    padding: 0; }
  .pl0-m {
    padding-left: 0; }
  .pr0-m {
    padding-right: 0; }
  .pb0-m {
    padding-bottom: 0; }
  .pt0-m {
    padding-top: 0; }
  .pv0-m {
    padding-top: 0;
    padding-bottom: 0; }
  .ph0-m {
    padding-left: 0;
    padding-right: 0; }
  .ma0-m {
    margin: 0; }
  .ml0-m {
    margin-left: 0; }
  .mr0-m {
    margin-right: 0; }
  .mb0-m {
    margin-bottom: 0; }
  .mt0-m {
    margin-top: 0; }
  .mv0-m {
    margin-top: 0;
    margin-bottom: 0; }
  .mh0-m {
    margin-left: 0;
    margin-right: 0; }
  .pa1-m {
    padding: 0.25rem; }
  .pl1-m {
    padding-left: 0.25rem; }
  .pr1-m {
    padding-right: 0.25rem; }
  .pb1-m {
    padding-bottom: 0.25rem; }
  .pt1-m {
    padding-top: 0.25rem; }
  .pv1-m {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .ph1-m {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .ma1-m {
    margin: 0.25rem; }
  .ml1-m {
    margin-left: 0.25rem; }
  .mr1-m {
    margin-right: 0.25rem; }
  .mb1-m {
    margin-bottom: 0.25rem; }
  .mt1-m {
    margin-top: 0.25rem; }
  .mv1-m {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .mh1-m {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .pa2-m {
    padding: 0.5rem; }
  .pl2-m {
    padding-left: 0.5rem; }
  .pr2-m {
    padding-right: 0.5rem; }
  .pb2-m {
    padding-bottom: 0.5rem; }
  .pt2-m {
    padding-top: 0.5rem; }
  .pv2-m {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .ph2-m {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .ma2-m {
    margin: 0.5rem; }
  .ml2-m {
    margin-left: 0.5rem; }
  .mr2-m {
    margin-right: 0.5rem; }
  .mb2-m {
    margin-bottom: 0.5rem; }
  .mt2-m {
    margin-top: 0.5rem; }
  .mv2-m {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .mh2-m {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .pa3-m {
    padding: 1rem; }
  .pl3-m {
    padding-left: 1rem; }
  .pr3-m {
    padding-right: 1rem; }
  .pb3-m {
    padding-bottom: 1rem; }
  .pt3-m {
    padding-top: 1rem; }
  .pv3-m {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .ph3-m {
    padding-left: 1rem;
    padding-right: 1rem; }
  .ma3-m {
    margin: 1rem; }
  .ml3-m {
    margin-left: 1rem; }
  .mr3-m {
    margin-right: 1rem; }
  .mb3-m {
    margin-bottom: 1rem; }
  .mt3-m {
    margin-top: 1rem; }
  .mv3-m {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .mh3-m {
    margin-left: 1rem;
    margin-right: 1rem; }
  .pa4-m {
    padding: 1.5rem; }
  .pl4-m {
    padding-left: 1.5rem; }
  .pr4-m {
    padding-right: 1.5rem; }
  .pb4-m {
    padding-bottom: 1.5rem; }
  .pt4-m {
    padding-top: 1.5rem; }
  .pv4-m {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .ph4-m {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .ma4-m {
    margin: 1.5rem; }
  .ml4-m {
    margin-left: 1.5rem; }
  .mr4-m {
    margin-right: 1.5rem; }
  .mb4-m {
    margin-bottom: 1.5rem; }
  .mt4-m {
    margin-top: 1.5rem; }
  .mv4-m {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .mh4-m {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .pa5-m {
    padding: 2rem; }
  .pl5-m {
    padding-left: 2rem; }
  .pr5-m {
    padding-right: 2rem; }
  .pb5-m {
    padding-bottom: 2rem; }
  .pt5-m {
    padding-top: 2rem; }
  .pv5-m {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .ph5-m {
    padding-left: 2rem;
    padding-right: 2rem; }
  .ma5-m {
    margin: 2rem; }
  .ml5-m {
    margin-left: 2rem; }
  .mr5-m {
    margin-right: 2rem; }
  .mb5-m {
    margin-bottom: 2rem; }
  .mt5-m {
    margin-top: 2rem; }
  .mv5-m {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .mh5-m {
    margin-left: 2rem;
    margin-right: 2rem; }
  .pa6-m {
    padding: 3rem; }
  .pl6-m {
    padding-left: 3rem; }
  .pr6-m {
    padding-right: 3rem; }
  .pb6-m {
    padding-bottom: 3rem; }
  .pt6-m {
    padding-top: 3rem; }
  .pv6-m {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .ph6-m {
    padding-left: 3rem;
    padding-right: 3rem; }
  .ma6-m {
    margin: 3rem; }
  .ml6-m {
    margin-left: 3rem; }
  .mr6-m {
    margin-right: 3rem; }
  .mb6-m {
    margin-bottom: 3rem; }
  .mt6-m {
    margin-top: 3rem; }
  .mv6-m {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .mh6-m {
    margin-left: 3rem;
    margin-right: 3rem; }
  .pa7-m {
    padding: 4rem; }
  .pl7-m {
    padding-left: 4rem; }
  .pr7-m {
    padding-right: 4rem; }
  .pb7-m {
    padding-bottom: 4rem; }
  .pt7-m {
    padding-top: 4rem; }
  .pv7-m {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .ph7-m {
    padding-left: 4rem;
    padding-right: 4rem; }
  .ma7-m {
    margin: 4rem; }
  .ml7-m {
    margin-left: 4rem; }
  .mr7-m {
    margin-right: 4rem; }
  .mb7-m {
    margin-bottom: 4rem; }
  .mt7-m {
    margin-top: 4rem; }
  .mv7-m {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .mh7-m {
    margin-left: 4rem;
    margin-right: 4rem; }
  .pa8-m {
    padding: 6rem; }
  .pl8-m {
    padding-left: 6rem; }
  .pr8-m {
    padding-right: 6rem; }
  .pb8-m {
    padding-bottom: 6rem; }
  .pt8-m {
    padding-top: 6rem; }
  .pv8-m {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .ph8-m {
    padding-left: 6rem;
    padding-right: 6rem; }
  .ma8-m {
    margin: 6rem; }
  .ml8-m {
    margin-left: 6rem; }
  .mr8-m {
    margin-right: 6rem; }
  .mb8-m {
    margin-bottom: 6rem; }
  .mt8-m {
    margin-top: 6rem; }
  .mv8-m {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .mh8-m {
    margin-left: 6rem;
    margin-right: 6rem; }
  .pa9-m {
    padding: 8rem; }
  .pl9-m {
    padding-left: 8rem; }
  .pr9-m {
    padding-right: 8rem; }
  .pb9-m {
    padding-bottom: 8rem; }
  .pt9-m {
    padding-top: 8rem; }
  .pv9-m {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .ph9-m {
    padding-left: 8rem;
    padding-right: 8rem; }
  .ma9-m {
    margin: 8rem; }
  .ml9-m {
    margin-left: 8rem; }
  .mr9-m {
    margin-right: 8rem; }
  .mb9-m {
    margin-bottom: 8rem; }
  .mt9-m {
    margin-top: 8rem; }
  .mv9-m {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .mh9-m {
    margin-left: 8rem;
    margin-right: 8rem; } }

@media (min-width: 70em) {
  .pa0-l {
    padding: 0; }
  .pl0-l {
    padding-left: 0; }
  .pr0-l {
    padding-right: 0; }
  .pb0-l {
    padding-bottom: 0; }
  .pt0-l {
    padding-top: 0; }
  .pv0-l {
    padding-top: 0;
    padding-bottom: 0; }
  .ph0-l {
    padding-left: 0;
    padding-right: 0; }
  .ma0-l {
    margin: 0; }
  .ml0-l {
    margin-left: 0; }
  .mr0-l {
    margin-right: 0; }
  .mb0-l {
    margin-bottom: 0; }
  .mt0-l {
    margin-top: 0; }
  .mv0-l {
    margin-top: 0;
    margin-bottom: 0; }
  .mh0-l {
    margin-left: 0;
    margin-right: 0; }
  .pa1-l {
    padding: 0.25rem; }
  .pl1-l {
    padding-left: 0.25rem; }
  .pr1-l {
    padding-right: 0.25rem; }
  .pb1-l {
    padding-bottom: 0.25rem; }
  .pt1-l {
    padding-top: 0.25rem; }
  .pv1-l {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .ph1-l {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .ma1-l {
    margin: 0.25rem; }
  .ml1-l {
    margin-left: 0.25rem; }
  .mr1-l {
    margin-right: 0.25rem; }
  .mb1-l {
    margin-bottom: 0.25rem; }
  .mt1-l {
    margin-top: 0.25rem; }
  .mv1-l {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .mh1-l {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .pa2-l {
    padding: 0.5rem; }
  .pl2-l {
    padding-left: 0.5rem; }
  .pr2-l {
    padding-right: 0.5rem; }
  .pb2-l {
    padding-bottom: 0.5rem; }
  .pt2-l {
    padding-top: 0.5rem; }
  .pv2-l {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .ph2-l {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .ma2-l {
    margin: 0.5rem; }
  .ml2-l {
    margin-left: 0.5rem; }
  .mr2-l {
    margin-right: 0.5rem; }
  .mb2-l {
    margin-bottom: 0.5rem; }
  .mt2-l {
    margin-top: 0.5rem; }
  .mv2-l {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .mh2-l {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .pa3-l {
    padding: 1rem; }
  .pl3-l {
    padding-left: 1rem; }
  .pr3-l {
    padding-right: 1rem; }
  .pb3-l {
    padding-bottom: 1rem; }
  .pt3-l {
    padding-top: 1rem; }
  .pv3-l {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .ph3-l {
    padding-left: 1rem;
    padding-right: 1rem; }
  .ma3-l {
    margin: 1rem; }
  .ml3-l {
    margin-left: 1rem; }
  .mr3-l {
    margin-right: 1rem; }
  .mb3-l {
    margin-bottom: 1rem; }
  .mt3-l {
    margin-top: 1rem; }
  .mv3-l {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .mh3-l {
    margin-left: 1rem;
    margin-right: 1rem; }
  .pa4-l {
    padding: 1.5rem; }
  .pl4-l {
    padding-left: 1.5rem; }
  .pr4-l {
    padding-right: 1.5rem; }
  .pb4-l {
    padding-bottom: 1.5rem; }
  .pt4-l {
    padding-top: 1.5rem; }
  .pv4-l {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .ph4-l {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .ma4-l {
    margin: 1.5rem; }
  .ml4-l {
    margin-left: 1.5rem; }
  .mr4-l {
    margin-right: 1.5rem; }
  .mb4-l {
    margin-bottom: 1.5rem; }
  .mt4-l {
    margin-top: 1.5rem; }
  .mv4-l {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .mh4-l {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .pa5-l {
    padding: 2rem; }
  .pl5-l {
    padding-left: 2rem; }
  .pr5-l {
    padding-right: 2rem; }
  .pb5-l {
    padding-bottom: 2rem; }
  .pt5-l {
    padding-top: 2rem; }
  .pv5-l {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .ph5-l {
    padding-left: 2rem;
    padding-right: 2rem; }
  .ma5-l {
    margin: 2rem; }
  .ml5-l {
    margin-left: 2rem; }
  .mr5-l {
    margin-right: 2rem; }
  .mb5-l {
    margin-bottom: 2rem; }
  .mt5-l {
    margin-top: 2rem; }
  .mv5-l {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .mh5-l {
    margin-left: 2rem;
    margin-right: 2rem; }
  .pa6-l {
    padding: 3rem; }
  .pl6-l {
    padding-left: 3rem; }
  .pr6-l {
    padding-right: 3rem; }
  .pb6-l {
    padding-bottom: 3rem; }
  .pt6-l {
    padding-top: 3rem; }
  .pv6-l {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .ph6-l {
    padding-left: 3rem;
    padding-right: 3rem; }
  .ma6-l {
    margin: 3rem; }
  .ml6-l {
    margin-left: 3rem; }
  .mr6-l {
    margin-right: 3rem; }
  .mb6-l {
    margin-bottom: 3rem; }
  .mt6-l {
    margin-top: 3rem; }
  .mv6-l {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .mh6-l {
    margin-left: 3rem;
    margin-right: 3rem; }
  .pa7-l {
    padding: 4rem; }
  .pl7-l {
    padding-left: 4rem; }
  .pr7-l {
    padding-right: 4rem; }
  .pb7-l {
    padding-bottom: 4rem; }
  .pt7-l {
    padding-top: 4rem; }
  .pv7-l {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .ph7-l {
    padding-left: 4rem;
    padding-right: 4rem; }
  .ma7-l {
    margin: 4rem; }
  .ml7-l {
    margin-left: 4rem; }
  .mr7-l {
    margin-right: 4rem; }
  .mb7-l {
    margin-bottom: 4rem; }
  .mt7-l {
    margin-top: 4rem; }
  .mv7-l {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .mh7-l {
    margin-left: 4rem;
    margin-right: 4rem; }
  .pa8-l {
    padding: 6rem; }
  .pl8-l {
    padding-left: 6rem; }
  .pr8-l {
    padding-right: 6rem; }
  .pb8-l {
    padding-bottom: 6rem; }
  .pt8-l {
    padding-top: 6rem; }
  .pv8-l {
    padding-top: 6rem;
    padding-bottom: 6rem; }
  .ph8-l {
    padding-left: 6rem;
    padding-right: 6rem; }
  .ma8-l {
    margin: 6rem; }
  .ml8-l {
    margin-left: 6rem; }
  .mr8-l {
    margin-right: 6rem; }
  .mb8-l {
    margin-bottom: 6rem; }
  .mt8-l {
    margin-top: 6rem; }
  .mv8-l {
    margin-top: 6rem;
    margin-bottom: 6rem; }
  .mh8-l {
    margin-left: 6rem;
    margin-right: 6rem; }
  .pa9-l {
    padding: 8rem; }
  .pl9-l {
    padding-left: 8rem; }
  .pr9-l {
    padding-right: 8rem; }
  .pb9-l {
    padding-bottom: 8rem; }
  .pt9-l {
    padding-top: 8rem; }
  .pv9-l {
    padding-top: 8rem;
    padding-bottom: 8rem; }
  .ph9-l {
    padding-left: 8rem;
    padding-right: 8rem; }
  .ma9-l {
    margin: 8rem; }
  .ml9-l {
    margin-left: 8rem; }
  .mr9-l {
    margin-right: 8rem; }
  .mb9-l {
    margin-bottom: 8rem; }
  .mt9-l {
    margin-top: 8rem; }
  .mv9-l {
    margin-top: 8rem;
    margin-bottom: 8rem; }
  .mh9-l {
    margin-left: 8rem;
    margin-right: 8rem; } }

/*

   WIDTHS
   Docs: http://tachyons.io/docs/layout/widths/

   Base:
     w = width

     Modifiers
       1 = 1st step in width scale
       2 = 2nd step in width scale
       3 = 3rd step in width scale
       4 = 4th step in width scale
       5 = 5th step in width scale

       -10  = literal value 10%
       -20  = literal value 20%
       -25  = literal value 25%
       -30  = literal value 30%
       -33  = literal value 33%
       -34  = literal value 34%
       -40  = literal value 40%
       -50  = literal value 50%
       -60  = literal value 60%
       -70  = literal value 70%
       -75  = literal value 75%
       -80  = literal value 80%
       -90  = literal value 90%
       -100 = literal value 100%

       -third      = 100% / 3 (Not supported in opera mini or IE8)
       -two-thirds = 100% / 1.5 (Not supported in opera mini or IE8)
       -auto       = string value auto


     Media Query Extensions:
       -ns = not-small
       -m  = medium
       -l  = large

  */
/* Width Scale */
.w1 {
  width: 1rem; }

.w2 {
  width: 2rem; }

.w3 {
  width: 4rem; }

.w4 {
  width: 8rem; }

.w5 {
  width: 16rem; }

.w-10 {
  width: 10%; }

.w-15 {
  width: 15%; }

.w-20 {
  width: 20%; }

.w-25 {
  width: 25%; }

.w-30 {
  width: 30%; }

.w-33 {
  width: 33%; }

.w-34 {
  width: 34%; }

.w-40 {
  width: 40%; }

.w-50 {
  width: 50%; }

.w-60 {
  width: 60%; }

.w-70 {
  width: 70%; }

.w-75 {
  width: 75%; }

.w-80 {
  width: 80%; }

.w-90 {
  width: 90%; }

.w-100 {
  width: 100%; }

.w-third {
  width: 33.33333%; }

.w-two-thirds {
  width: 66.66667%; }

.w-auto {
  width: auto; }

@media screen and (min-width: 30em) {
  .w1-ns {
    width: 1rem; }
  .w2-ns {
    width: 2rem; }
  .w3-ns {
    width: 4rem; }
  .w4-ns {
    width: 8rem; }
  .w5-ns {
    width: 16rem; }
  .w-10-ns {
    width: 10%; }
  .w-15-ns {
    width: 15%; }
  .w-20-ns {
    width: 20%; }
  .w-25-ns {
    width: 25%; }
  .w-30-ns {
    width: 30%; }
  .w-33-ns {
    width: 33%; }
  .w-34-ns {
    width: 34%; }
  .w-40-ns {
    width: 40%; }
  .w-50-ns {
    width: 50%; }
  .w-60-ns {
    width: 60%; }
  .w-70-ns {
    width: 70%; }
  .w-75-ns {
    width: 75%; }
  .w-80-ns {
    width: 80%; }
  .w-90-ns {
    width: 90%; }
  .w-100-ns {
    width: 100%; }
  .w-third-ns {
    width: 33.33333%; }
  .w-two-thirds-ns {
    width: 66.66667%; }
  .w-auto-ns {
    width: auto; } }

@media (min-width: 29em) {
  .w1-sm {
    width: 1rem; }
  .w2-sm {
    width: 2rem; }
  .w3-sm {
    width: 4rem; }
  .w4-sm {
    width: 8rem; }
  .w5-sm {
    width: 16rem; }
  .w-10-sm {
    width: 10%; }
  .w-15-sm {
    width: 15%; }
  .w-20-sm {
    width: 20%; }
  .w-25-sm {
    width: 25%; }
  .w-30-sm {
    width: 30%; }
  .w-33-sm {
    width: 33%; }
  .w-34-sm {
    width: 34%; }
  .w-40-sm {
    width: 40%; }
  .w-50-sm {
    width: 50%; }
  .w-60-sm {
    width: 60%; }
  .w-70-sm {
    width: 70%; }
  .w-75-sm {
    width: 75%; }
  .w-80-sm {
    width: 80%; }
  .w-90-sm {
    width: 90%; }
  .w-100-sm {
    width: 100%; }
  .w-third-sm {
    width: 33.33333%; }
  .w-two-thirds-sm {
    width: 66.66667%; }
  .w-auto-sm {
    width: auto; } }

@media (min-width: 48em) {
  .w1-m {
    width: 1rem; }
  .w2-m {
    width: 2rem; }
  .w3-m {
    width: 4rem; }
  .w4-m {
    width: 8rem; }
  .w5-m {
    width: 16rem; }
  .w-10-m {
    width: 10%; }
  .w-15-m {
    width: 15%; }
  .w-20-m {
    width: 20%; }
  .w-25-m {
    width: 25%; }
  .w-30-m {
    width: 30%; }
  .w-33-m {
    width: 33%; }
  .w-34-m {
    width: 34%; }
  .w-40-m {
    width: 40%; }
  .w-50-m {
    width: 50%; }
  .w-60-m {
    width: 60%; }
  .w-70-m {
    width: 70%; }
  .w-75-m {
    width: 75%; }
  .w-80-m {
    width: 80%; }
  .w-90-m {
    width: 90%; }
  .w-100-m {
    width: 100%; }
  .w-third-m {
    width: 33.33333%; }
  .w-two-thirds-m {
    width: 66.66667%; }
  .w-auto-m {
    width: auto; } }

@media (min-width: 70em) {
  .w1-l {
    width: 1rem; }
  .w2-l {
    width: 2rem; }
  .w3-l {
    width: 4rem; }
  .w4-l {
    width: 8rem; }
  .w5-l {
    width: 16rem; }
  .w-10-l {
    width: 10%; }
  .w-15-l {
    width: 15%; }
  .w-20-l {
    width: 20%; }
  .w-25-l {
    width: 25%; }
  .w-30-l {
    width: 30%; }
  .w-33-l {
    width: 33%; }
  .w-34-l {
    width: 34%; }
  .w-40-l {
    width: 40%; }
  .w-50-l {
    width: 50%; }
  .w-60-l {
    width: 60%; }
  .w-70-l {
    width: 70%; }
  .w-75-l {
    width: 75%; }
  .w-80-l {
    width: 80%; }
  .w-90-l {
    width: 90%; }
  .w-100-l {
    width: 100%; }
  .w-third-l {
    width: 33.33333%; }
  .w-two-thirds-l {
    width: 66.66667%; }
  .w-auto-l {
    width: auto; } }

.card {
  position: relative;
  height: 20rem; }

.pos_rel {
  position: relative; }

.card_name {
  position: absolute;
  bottom: 40px;
  left: 32px; }

.card_address {
  position: absolute;
  bottom: 16px;
  left: 32px; }

.product_card {
  position: relative; }

.product_type {
  position: absolute;
  top: 8px;
  right: 16px; }

.product_sale {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center; }

.product_flag {
  position: absolute;
  bottom: 16px;
  left: 0px;
  width: 80px;
  height: 24px; }

.cart_remove {
  position: absolute;
  top: 16px;
  right: 16px; }

.product-image {
  width: 150px;
  height: 100%; }

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 24px;
  right: 10%;
  top: 36px; }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47; }

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #00a917; }

/* Position and sizing of clickable cross button */
.bm-cross-button {
  width: 24px;
  height: 24px;
  right: 10%;
  top: 36px; }

/* Color/shape of close button cross */
.bm-cross {
  background: transparent; }

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 0px;
  height: 100%; }

/* General sidebar styles */
.bm-menu {
  background: #141414;
  padding: 2.5em 1.5em 0;
  font-size: 1.618em;
  height: 100%; }

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center; }

/* Individual item */
.bm-item {
  display: flex;
  align-items: center;
  color: white;
  padding: 0 3rem; }

/* Styling of overlay */
.bm-overlay {
  background: rgba(238, 238, 238, 0.822); }

