$spacing-none: 0;
$spacing-micro: .25rem; /* 4px */
$spacing-tiny: .5rem; /* 8px */
$spacing-small: 1rem; /* 16px */
$spacing-normal: 1.5rem; /* 24px */
$spacing-semi: 2rem; /* 32px */
$spacing-large: 3rem; /* 48px */
$spacing-extra-large: 4rem; /* 64px */
$spacing-extra-extra-large: 6rem; /* 96px */
$spacing-extra-extra-extra-large: 8rem; /* 128px */

$space-map: (
  '0': $spacing-none,
  '1': $spacing-micro,
  '2': $spacing-tiny,
  '3': $spacing-small,
  '4': $spacing-normal,
  '5': $spacing-semi,
  '6': $spacing-large,
  '7': $spacing-extra-large,
  '8': $spacing-extra-extra-large,
  '9': $spacing-extra-extra-extra-large
);

/*
   SPACING
   A 10 step scale ranging from 0 to 8rem.
   Base:
     p = padding
     m = margin
   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left
     0 = none
     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale
     8 = 8th step in spacing scale
     9 - 9th step in spacing scale
   Media Query Extensions:
     -m  = medium
     -l  = large
*/

.break-word {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

@mixin spacing($scale, $value, $extension: '') {
  .pa#{$scale}#{$extension} { padding: $value; }
  .pl#{$scale}#{$extension} { padding-left: $value; }
  .pr#{$scale}#{$extension} { padding-right: $value; }
  .pb#{$scale}#{$extension} { padding-bottom: $value; }
  .pt#{$scale}#{$extension} { padding-top: $value; }
  .pv#{$scale}#{$extension} {
    padding-top: $value;
    padding-bottom: $value;
  }
  .ph#{$scale}#{$extension} {
    padding-left: $value;
    padding-right: $value;
  }

  .ma#{$scale}#{$extension} { margin: $value; }
  .ml#{$scale}#{$extension} { margin-left: $value; }
  .mr#{$scale}#{$extension} { margin-right: $value; }
  .mb#{$scale}#{$extension} { margin-bottom: $value; }
  .mt#{$scale}#{$extension} { margin-top: $value; }
  .mv#{$scale}#{$extension} {
    margin-top: $value;
    margin-bottom: $value;
  }
  .mh#{$scale}#{$extension} {
    margin-left: $value;
    margin-right: $value;
  }
}

@each $scale, $value in $space-map {
  @include spacing($scale, $value);
}

@media #{$breakpoint-small} {
  @each $scale, $value in $space-map {
    @include spacing($scale, $value, '-s');
  }
}

@media #{$breakpoint-medium} {
  @each $scale, $value in $space-map {
    @include spacing($scale, $value, '-m');
  }
}

@media #{$breakpoint-large} {
  @each $scale, $value in $space-map {
    @include spacing($scale, $value, '-l');
  }
}