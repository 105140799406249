
body {
 font-family:  aktiv-grotesk, sans-serif;
 font-weight: 400;
 margin: 0;
}

a.link {
  font-weight: 400;
  font-family:  aktiv-grotesk, sans-serif;
}

.parallax {
  background-attachment: fixed;
}

// Border Width 

.bw-cta {
  border-width: 2px;
}

// Font Colors

.black-grad {
  background: linear-gradient(to right, rgb(255, 255, 255) 0%, rgb(0, 0, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cart-img {
  height: 100px;
  width: auto;
}

.shadow-card {
  box-shadow: 0px 0px 4px 4px rgba( 0, 0, 0, 0.025 );
}

.pointer {
  cursor: pointer;
}

.rotate {
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

.form-cl {
  border-width: 1px;
  background-color: white;
  font-size: 14px;
  line-height: 21px;
  padding: 4px 8px;
}


.cart-table {
  border-collapse: collapse;
  th {
    padding-bottom: 8px;
    // border-bottom: 1px solid #000;
  }
  tr {
    // border-bottom: 1px solid #000;
  }
  
}

.order-table {
  border-collapse: collapse;
  th, td {
    padding: 8px 16px;
    // border-bottom: 1px solid #000;
  }
  tr {
    // border-bottom: 1px solid #000;
  }
  
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.shadow-6 {
  -moz-box-shadow:    0px 6px 5px 4px rgb(221, 221, 221);
  -webkit-box-shadow: 0px 6px 5px 4px rgb(221, 221, 221);
  box-shadow:         0px 6px 5px 4px rgb(221, 221, 221);
}